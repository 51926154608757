import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

const EmailInput = ({ selectedMails, setSelectedMails }) => {
  const [inputText, setInputText] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    setInvalidEmail(false);
    setDuplicateEmail(false);
  };

  const handleSpacePress = (e) => {
    if (e.key === ' ') {
      e.preventDefault();
      if (validateEmail(inputText)) {
        if (!selectedMails.includes(inputText.trim())) {
          setSelectedMails([...selectedMails, inputText.trim()]);
          setInputText('');
          setInvalidEmail(false);
          setDuplicateEmail(false);
        } else {
          setDuplicateEmail(true);
        }
      } else {
        setInvalidEmail(true);
      }
    }
  };

  const validateEmail = (email) => {
    // Basic email validation using regex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const removeMail = (index) => {
    const updatedMails = [...selectedMails];
    updatedMails.splice(index, 1);
    setSelectedMails(updatedMails);
  };

  return (
    <div>
      <div className="mb-3">
        <input
          type="text"
          className={`form-control ${invalidEmail || duplicateEmail ? 'is-invalid' : ''}`}
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleSpacePress}
          placeholder="Enter an email and press space..."
        />
        {invalidEmail && (
          <div className="invalid-feedback">Invalid email address</div>
        )}
        {duplicateEmail && (
          <div className="invalid-feedback">Email already added</div>
        )}
      </div>
      <div className="mb-3">
        {selectedMails.map((mail, index) => (
          <div key={index} className="d-inline-block m-1 bg-light border p-1 rounded">
            <span>{mail}</span>
            <button
              className="btn btn-sm btn-danger ml-1"
              onClick={() => removeMail(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmailInput;
