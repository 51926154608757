import axios from "axios";
import jwt_decode from "jwt-decode";

const refresh = async (url, key) => {
  try {
    const response = await axios.post(url, {
      refreshToken: key,
    });
    const { status, data, err } = response;
    if (status === 200 && data) {
      const { status, accessToken, refreshToken } = data;
      if (status) {
        sessionStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
      }
    } else {
      console.error("Token refresh failed:", err);
    }
  } catch (err) {
    console.error("Token refresh failed:", err);
  }
};

export const checkTokenExpiration = async () => {
  const currentTime = new Date().getTime();
  const decode = decodeToken("accessToken", "session");
  if (decode) {
    if (decode.exp * 1000 < currentTime) {
      await refresh(`client/refresh`, localStorage.getItem("refreshToken"));
    }
    return {
      headers: {
        authorization: `Bearer ${sessionStorage.getItem("access")}`,
      },
    };
  }
  return;
};

export const checkRefreshToken = async () => {
  const decode = decodeToken("refreshToken");
  const currentTime = new Date().getTime();
  if (decode) {
    if (decode.exp * 1000 < currentTime) {
      return true;
    }
    return false;
  }
  return false;
};

export const decodeToken = (key, storage = "local") => {
  return storage === "local"
    ? localStorage.getItem(key) && jwt_decode(localStorage.getItem(key))
    : sessionStorage.getItem(key) && jwt_decode(sessionStorage.getItem(key));
};
