import logo from "../assets/img/auto-logo.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import Language from "./language";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MDBCheckbox } from "mdb-react-ui-kit";
import jwt_decode from "jwt-decode";

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [oldpassword, setOldpassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [Messageoldpassword, setMessageOldpassword] = useState();
  const [Messagenewpassword, setMessageNewpassword] = useState();
  const url = process.env.REACT_APP_BASEURL;
  const [Messageconfirmpassword, setMessageConfirmpassword] = useState();
  const [vuepassword, setVuepassword] = useState(false);

  const check_verif = () => {
    if (vuepassword === false) {
      setVuepassword(true);
    } else {
      setVuepassword(false);
    }
  };

  const change_password = () => {
    const dc = jwt_decode(sessionStorage.getItem("accessToken"));
    let verif = 0;
    if (oldpassword === "") {
      verif = 1;
      setMessageOldpassword("Old password is empty");
    }
    if (newpassword === "") {
      setMessageNewpassword("New password is empty");
      verif = 1;
    }
    if (confirmpassword === "") {
      verif = 1;
      setMessageConfirmpassword("You must confirm the new password");
    }

    if (verif === 0) {
      if (newpassword === confirmpassword) {
        Axios.post(url + "?p=change_password", {
          username: dc.username,
          newpassword: newpassword,
          oldpassword: oldpassword,
        }).then((response) => {
          if (response.data.status) {
            notifySuccess("The password has been changed");
            handleModelClosechangePassword();
          } else {
            notifyError(response.data.err)
          }
        });
      } else {
        setMessageConfirmpassword(
          "The first password and the second password do not match"
        );
      }
    }
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const handleModelClosechangePassword = () => {
    setShowChangePassword(false);
    setOldpassword("");
    setNewpassword("");
    setConfirmpassword("");
    setMessageOldpassword("");
    setMessageNewpassword("");
    setMessageConfirmpassword("");
  };

  const logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Modal
        show={showChangePassword}
        onHide={handleModelClosechangePassword}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Old password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setOldpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageoldpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                New password<span className="text-danger pl-3">*</span>
              </label>
              <input
                type={vuepassword ? "text" : "password"}
                id="new-password"
                onChange={(e) => {
                  setNewpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messagenewpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Confirm password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setConfirmpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageconfirmpassword}
              </div>
            </div>
            <MDBCheckbox
              className="ml-30"
              name="flexCheck"
              onChange={check_verif}
              value={vuepassword}
              id="shwpssw"
              label="Show password"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModelClosechangePassword}>
            Cancel
          </Button>
          <Button variant="primary" onClick={change_password}>
            Change password
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        id="kt_header"
        className="header header-fixed bg-white text-dark d-print-none"
      >
        <Toaster position="top-right" reverseOrder={false} />
        <div className=" container  d-flex align-items-stretch justify-content-between py-3">
          <div className="d-flex align-items-stretch mr-3">
            <div className="header-logo">
              <a href={"/admin/home"}>
                <img
                  alt="Logo"
                  src={logo}
                  className="logo-default max-h-40px"
                />
              </a>
            </div>

            <div
              className="header-menu-wrapper header-menu-wrapper-left"
              id="kt_header_menu_wrapper"
            >
              <div
                id="kt_header_menu"
                className="header-menu header-menu-left header-menu-mobile  header-menu-layout-default "
              >
                <ul className="menu-nav">
                  <li className="menu-item menu-item-rel">
                    <a href="/admin/home" className="menu-link">
                      <span className="text-dark-75 font-weight-bolder">
                        {t("Header.1")}
                      </span>
                      <i className="menu-arrow pb-2"></i>
                    </a>
                  </li>

                  <li className="menu-item menu-item-rel pt-6">
                    <div className="menu-link">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dropdown-basic"
                          className="w-55px h-40px text-white pt-0 pb-0"
                        >
                          <span className="menu-link menu-toggle dropdownEl">
                            <span className="text-dark-75 font-weight-bolder dropdownEl">
                              {t("Header.2")}
                            </span>
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-180px">
                          <Dropdown.Item
                            href="/admin/add_customer"
                            className="navi-link"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.3")}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="/admin/customer_list"
                            className="dropdown-item border-bottom"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.4")}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="/admin/add_contact"
                            className="dropdown-item"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.5")}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="/admin/contact_list"
                            className="dropdown-item"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.6")}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="/admin/add_site"
                            className="dropdown-item"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.14")}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="/admin/site_list"
                            className="dropdown-item"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.15")}
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>

                  <li className="menu-item menu-item-rel pt-6">
                    <div className="menu-link">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dropdown-basic"
                          className="w-55px h-40px text-white pt-0 pb-0"
                        >
                          <span className="menu-link menu-toggle dropdownEl">
                            <span className="text-dark-75 font-weight-bolder dropdownEl">
                              {t("Header.10")}
                            </span>
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-190px">
                          <Dropdown.Item
                            href="/admin/add_machine"
                            className="dropdown-item"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.11")}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="/admin/machine_list"
                            className="dropdown-item border-bottom"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.12")}
                            </span>
                          </Dropdown.Item>

                          <Dropdown.Item
                            href="/admin/machine_descriptors"
                            className="dropdown-item"
                          >
                            <span className="symbol symbol-20 mr-1"></span>
                            <span className="navi-text font-weight-bolder">
                              {t("Header.13")}
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="topbar">
            <div className="dropdown mr-0 pt-2">
              <span className="menu-link">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dropdown-basic"
                    className="w-55px h-40px text-white pt-0 pb-0"
                  >
                    <div
                      className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
                      id="open-notification"
                    >
                      <i className="far fa-bell text-dark-75 mr-2"></i>
                      <span className="label label-rounded label-danger">
                        <div id="unreadnot">0</div>
                      </span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-250px">
                    <Dropdown.Item href="#">
                      <form className="p-0">
                        <div className="d-flex align-items-center pt-0 pb-4 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                          <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-15 font-weight-bolder w-200px">
                            {t("Header.9")}
                          </div>
                        </div>

                        <div className="scroll scroll-push text-center h-100px">
                          <div id="notificationcontent" className="pt-11"></div>
                        </div>
                      </form>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
            <div className="">
              <Language />
            </div>
            <div className="dropdown pt-2">
              <span className="menu-link">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dropdown-basic"
                    className="w-auto h-40px text-white pt-0 pb-0"
                  >
                    <div
                      className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
                      id="kt_quick_user_toggle"
                    >
                      <span className="text-dark-75 opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
                        Hi,
                      </span>
                      <span className="text-dark-75 opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                        {sessionStorage.getItem("username")}
                      </span>
                      <span className="symbol bg-light symbol-35">
                        <span className="symbol-label text-dark-75 font-size-h5 font-weight-bold bg-white-o-30">
                          DA
                        </span>
                      </span>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-180px">
                    <Dropdown.Item
                      onClick={setShowChangePassword}
                      className="navi-link border-bottom"
                    >
                      <span className="symbol symbol-20 mr-1"></span>
                      <span className="navi-text font-weight-bolder">
                        {t("Header.7")}
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        logout();
                      }}
                      className="navi-link"
                    >
                      <span className="symbol symbol-20 mr-1"></span>
                      <span className="navi-text font-weight-bolder">
                        {t("Header.8")}
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
