import axios from "axios";
import jwt_decode from "jwt-decode";

export const BASEURL = process.env.REACT_APP_BASEURL;

const refresh = async () => {
  try {
    const response = await axios.post(`${BASEURL}client/refresh`, {
      refreshToken: sessionStorage.getItem("refreshToken"),
    });
    const { status, data, err } = response;
    if (status === 200 && data) {
      const { status, accessToken, refreshToken } = data;
      if (status) {
        sessionStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
      }
    } else {
      console.error("Token refresh failed:", err);
    }
  } catch (err) {
    console.error("Token refresh failed:", err);
  }
};

export const checkTokenExpiration = async () => {
  if (sessionStorage.getItem("accessToken")) {
    const currentTime = new Date().getTime();
    const decode =
      sessionStorage.getItem("accessToken") &&
      jwt_decode(sessionStorage.getItem("accessToken"));
    if (decode) {
      if (decode.exp * 1000 < currentTime) {
        await refresh();
      }
      return {
        headers: {
          authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        },
      };
    }
    return;
  }
  return;
};
