import Header from "./header";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Header_mobile from "./header_mobile";
import { useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Moment from "moment";
import { SiGmail, SiMicrosoftexcel } from "react-icons/si";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FcPrint } from "react-icons/fc";
import toast from "react-hot-toast";
import { FaSave, FaShareAlt } from "react-icons/fa";
import { GoGitCompare } from "react-icons/go";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import foot from "../assets/img/foot.png";
import head from "../assets/img/head.png";

import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import "../../App.css";
import { useOutsideFinder } from "../../admin/utils/useOutsideFinder";

//import "moment/locale/fr";
function Report() {
  const exportToExcel = () => {
    // Get the content of the div element you want to export
    const divContent = document.getElementById("repprintall");

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.table_to_sheet(divContent);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate an Excel file buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the buffer to a Blob
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the Blob as an Excel file
    saveAs(blob, "exportedData.xlsx");
  };
  const navigate = useNavigate();

  const shareRef = useRef();
  useOutsideFinder(() => setShowShareList(false), shareRef);

  const mytb = [
    { symbole: "H", nom: "Hydrogen" },
    { symbole: "He", nom: "Helium" },
    { symbole: "Li", nom: "Lithium" },
    { symbole: "Be", nom: "Beryllium" },
    { symbole: "B", nom: "Boron" },
    { symbole: "C", nom: "Carbon" },
    { symbole: "N", nom: "Nitrogen" },
    { symbole: "O", nom: "Oxygen" },
    { symbole: "F", nom: "Fluorine" },
    { symbole: "Ne", nom: "Neon" },
    { symbole: "Na", nom: "Sodium" },
    { symbole: "Mg", nom: "Magnesium" },
    { symbole: "Al", nom: "Aluminum" },
    { symbole: "Si", nom: "Silicon" },
    { symbole: "P", nom: "Phosphorus" },
    { symbole: "S", nom: "Sulfur" },
    { symbole: "Cl", nom: "Chlorine" },
    { symbole: "Ar", nom: "Argon" },
    { symbole: "K", nom: "Potassium" },
    { symbole: "Ca", nom: "Calcium" },
    { symbole: "Sc", nom: "Scandium" },
    { symbole: "Ti", nom: "Titanium" },
    { symbole: "V", nom: "Vanadium" },
    { symbole: "Cr", nom: "Chromium" },
    { symbole: "Mn", nom: "Manganese" },
    { symbole: "Fe", nom: "Iron" },
    { symbole: "Ni", nom: "Nickel" },
    { symbole: "Co", nom: "Cobalt" },
    { symbole: "Cu", nom: "Copper" },
    { symbole: "Zn", nom: "Zinc" },
    { symbole: "Ga", nom: "Gallium" },
    { symbole: "Ge", nom: "Germanium" },
    { symbole: "As", nom: "Arsenic" },
    { symbole: "Se", nom: "Selenium" },
    { symbole: "Br", nom: "Bromine" },
    { symbole: "Kr", nom: "Krypton" },
    { symbole: "Rb", nom: "Rubidium" },
    { symbole: "Sr", nom: "Strontium" },
    { symbole: "Y", nom: "Yttrium" },
    { symbole: "Zr", nom: "Zirconium" },
    { symbole: "Nb", nom: "Niobium" },
    { symbole: "Mo", nom: "Molybdenum" },
    { symbole: "Tc", nom: "Technetium" },
    { symbole: "Ru", nom: "Ruthenium" },
    { symbole: "Rh", nom: "Rhodium" },
    { symbole: "Pd", nom: "Palladium" },
    { symbole: "Ag", nom: "Silver" },
    { symbole: "Cd", nom: "Cadmium" },
    { symbole: "In", nom: "Indium" },
    { symbole: "Sn", nom: "Tin" },
    { symbole: "Sb", nom: "Antimony" },
    { symbole: "I", nom: "Iodine" },
    { symbole: "Te", nom: "Tellurium" },
    { symbole: "Xe", nom: "Xenon" },
    { symbole: "Cs", nom: "Cesium" },
    { symbole: "Ba", nom: "Barium" },
    { symbole: "La", nom: "Lanthanum" },
    { symbole: "Ce", nom: "Cerium" },
    { symbole: "Pr", nom: "Praseodymium" },
    { symbole: "Nd", nom: "Neodymium" },
    { symbole: "Pm", nom: "Promethium" },
    { symbole: "Sm", nom: "Samarium" },
    { symbole: "Eu", nom: "Europium" },
    { symbole: "Gd", nom: "Gadolinium" },
    { symbole: "Tb", nom: "Terbium" },
    { symbole: "Dy", nom: "Dysprosium" },
    { symbole: "Ho", nom: "Holmium" },
    { symbole: "Er", nom: "Erbium" },
    { symbole: "Tm", nom: "Thulium" },
    { symbole: "Yb", nom: "Ytterbium" },
    { symbole: "Lu", nom: "Lutetium" },
    { symbole: "Hf", nom: "Hafnium" },
    { symbole: "Ta", nom: "Tantalum" },
    { symbole: "W", nom: "Tungsten" },
    { symbole: "Re", nom: "Rhenium" },
    { symbole: "Os", nom: "Osmium" },
    { symbole: "Ir", nom: "Iridium" },
    { symbole: "Pt", nom: "Platinum" },
    { symbole: "Au", nom: "Gold" },
    { symbole: "Hg", nom: "Mercury" },
    { symbole: "Tl", nom: "Thallium" },
    { symbole: "Pb", nom: "Lead" },
    { symbole: "Bi", nom: "Bismuth" },
    { symbole: "Po", nom: "Polonium" },
    { symbole: "At", nom: "Astatine" },
    { symbole: "Rn", nom: "Radon" },
    { symbole: "Fr", nom: "Francium" },
    { symbole: "Ra", nom: "Radium" },
    { symbole: "Ac", nom: "Actinium" },
    { symbole: "Th", nom: "Thorium" },
    { symbole: "Pa", nom: "Protactinium" },
    { symbole: "U", nom: "Uranium" },
    { symbole: "Np", nom: "Neptunium" },
    { symbole: "Pu", nom: "Plutonium" },
    { symbole: "Am", nom: "Americium" },
    { symbole: "Cm", nom: "Curium" },
    { symbole: "Bk", nom: "Berkelium" },
    { symbole: "Cf", nom: "Californium" },
    { symbole: "Es", nom: "Einsteinium" },
    { symbole: "Fm", nom: "Fermium" },
    { symbole: "Md", nom: "Mendelevium" },
    { symbole: "No", nom: "Nobelium" },
    { symbole: "Lr", nom: "Lawrencium" },
    { symbole: "Rf", nom: "Rutherfordium" },
    { symbole: "Db", nom: "Dubnium" },
    { symbole: "Sg", nom: "Seaborgium" },
    { symbole: "Bh", nom: "Bohrium" },
    { symbole: "Hs", nom: "Hassium" },
    { symbole: "Mt", nom: "Meitnerium" },
    { symbole: "Ds", nom: "Darmstadtium" },
    { symbole: "Rg", nom: "Roentgenium" },
    { symbole: "Cn", nom: "Copernicium" },
    { symbole: "Nh", nom: "Nihonium" },
    { symbole: "Fl", nom: "Flerovium" },
    { symbole: "Mc", nom: "Moscovium" },
    { symbole: "Lv", nom: "Livermorium" },
    { symbole: "Ts", nom: "Tennessine" },
    { symbole: "Og", nom: "Oganesson" },
  ];

  const { t } = useTranslation();
  if (localStorage.getItem("i18next") === "en") {
    Moment.locale("en");
  } else {
    Moment.locale("fr");
  }

  // 'fr'
  const url = process.env.REACT_APP_BASEURL;
  const [all_details, setAll_details] = useState([]);
  const [all_details1, setAll_details1] = useState([]);
  const [viewExport, setviewExport] = useState(false);
  const [datefromDetails, setdatefromDetails] = useState("");
  // const [datefromDetails1, setdatefromDetails1] = useState("");
  const [all_reportDetails, setall_reportDetails] = useState([]);
  const [all_reportDetails1, setall_reportDetails1] = useState([]);
  const [comparedReport, setcomparedReport] = useState([]);

  const [fluideBrand, setfluideBrand] = useState("");
  const [vfluideBrand, setvfluideBrand] = useState(true);

  const [fluidType, setfluidType] = useState("");
  const [vfluidType, setvfluidType] = useState(true);

  const [filterChanged, setfilterChanged] = useState("");
  const [vfilterChanged, setvfilterChanged] = useState(true);

  const [processDate, setprocessDate] = useState("");
  const [vprocessDate, setvprocessDate] = useState(true);

  const [equipHours, setequipHours] = useState("");
  const [vequipHours, setvequipHours] = useState(true);

  const [fluidHours, setfluidHours] = useState("");
  const [vfluidHours, setvfluidHours] = useState(true);

  const [fluidChanges, setfluidChanges] = useState("");
  const [vfluidChanges, setvfluidChanges] = useState(true);

  const [fluidGrade, setfluidGrade] = useState("");
  const [vfluidGrade, setvfluidGrade] = useState(true);

  const [viscosity100, setviscosity100] = useState("");
  const [vviscosity100, setvviscosity100] = useState(true);

  const [viscosity40, setviscosity40] = useState("");
  const [vviscosity40, setvviscosity40] = useState(true);

  const [tbn, settbn] = useState("");
  const [vtbn, setvtbn] = useState(true);

  const [iso, setiso] = useState("");
  const [viso, setviso] = useState(true);

  const [refresh, setrefresh] = useState(0);
  const [viewcompare, setviewcompare] = useState(false);
  const [machineId, setmachineId] = useState("");
  const [componentId, setcomponentId] = useState("");

  const [reportName, setreportName] = useState("");
  const [reportComment, setreportComment] = useState("");
  const [allsample, setallsample] = useState([]);

  const [reportdata1, setreportdata1] = useState([]);
  const [bgV, setbgV] = useState("bg-info");
  const [selectedValue, setselectedValue] = useState("Choose action");

  const location = useLocation();
  const size1 = " fs-11";
  const [showShareList, setShowShareList] = useState(false);
  const [complaintForm, setcomplaintForm] = useState(" col-xl-8");

  const [soot, setsoot] = useState("");
  const [vsoot, setvsoot] = useState(true);
  const [oxidatation, setoxidatation] = useState("");
  const [voxidatation, setvoxidatation] = useState(true);
  const [nitration, setnitration] = useState("");
  const [vnitration, setvnitration] = useState(true);
  const [sulfation, setsulfation] = useState("");
  const [vsulfation, setvsulfation] = useState(true);
  const [wation, setwation] = useState("");
  const [vwation, setvwation] = useState(true);
  const [antifreeze, setantifreeze] = useState("");
  const [vantifreeze, setvantifreeze] = useState(true);
  const [fuel, setfuel] = useState("");
  const [vfuel, setvfuel] = useState(true);
  const [pfc, setpfc] = useState("");
  const [vpfc, setvpfc] = useState(true);

  const OilCondition = [
    {
      id: 331,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Soot",
      RptdConcInCalibUnits: "-1",
      ConcInCalibUnits: 29,
      DateTime: "2023-04-05T08:05:11.000Z",
    },
    {
      id: 332,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Oxidation",
      RptdConcInCalibUnits: "-0",
      ConcInCalibUnits: 13,
      DateTime: "2023-04-05T08:05:18.000Z",
    },
    {
      id: 333,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Nitration",
      RptdConcInCalibUnits: "-1",
      ConcInCalibUnits: 8,
      DateTime: "2023-04-05T08:05:25.000Z",
    },
    {
      id: 332,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Sulfation",
      RptdConcInCalibUnits: "-0",
      ConcInCalibUnits: 19,
      DateTime: "2023-04-05T08:05:18.000Z",
    },
    {
      id: 333,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Wation",
      RptdConcInCalibUnits: "-1",
      ConcInCalibUnits: "N",
      DateTime: "2023-04-05T08:05:25.000Z",
    },
    {
      id: 333,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Antifreeze",
      RptdConcInCalibUnits: "-1",
      ConcInCalibUnits: "N",
      DateTime: "2023-04-05T08:05:25.000Z",
    },
    {
      id: 334,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Fuel",
      RptdConcInCalibUnits: "-1",
      ConcInCalibUnits: "N",
      DateTime: "2023-04-05T08:05:25.000Z",
    },
    {
      id: 335,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "PFC",
      RptdConcInCalibUnits: "-1",
      ConcInCalibUnits: "N",
      DateTime: "2023-04-05T08:05:25.000Z",
    },

    {
      id: 337,
      reportid: 4,
      resultindex: 4,
      resultname: "test du 05 avril 2023",
      sampleindex: 1,
      sampleid: "Reagent1",
      analytename: "Total base number",
      RptdConcInCalibUnits: "-1",
      ConcInCalibUnits: "5.4",
      DateTime: "2023-04-05T08:05:25.000Z",
    },
  ];

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Lab-report",
  });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const saveAsPDF = () => {
    const input = document.getElementById("divToCapture"); // Remplacez 'divToCapture' par l'ID de votre div
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("content.pdf");
      });
    }
  };

  function getAllreport() {
    const id = location.state?.reportid;

    Axios.post(url + "?p=getEngineReport", {
      machineId: machineId,
      actualReport: id,
      componentId: componentId,
    }).then((response) => {
      setcomparedReport(response.data);
    });
  }

  const get_all_details = async (d) => {
    const id = location.state?.reportid;

    Axios.post(url + "?p=get_all_report_info", {
      id: id,
    }).then((response) => {
      setAll_details(response.data);
      if (response.data) {
        setfluideBrand(response.data[0].fluidBrand);
        setfluidType(response.data[0].fluidType);
        setfluidGrade(response.data[0].fluidGrade);
        setprocessDate(response.data[0].sampleDate);
        setequipHours(response.data[0].equipmentHours);
        setfluidHours(response.data[0].fluidHours);
        setfluidChanges(response.data[0].fluidChanged);
        setfilterChanged(response.data[0].filterChanged);
        setviscosity100(response.data[0].viscosity100c);
        setviscosity40(response.data[0].viscosity40c);
        setsoot(response.data[0].soot);
        setoxidatation(response.data[0].oxidation);
        setnitration(response.data[0].nitration);
        setsulfation(response.data[0].sulfaction);
        setwation(response.data[0].wation);
        setantifreeze(response.data[0].antifreeze);
        setfuel(response.data[0].fuel);
        setpfc(response.data[0].PFC);
        settbn(response.data[0].tbn);
        setiso(response.data[0].iso);
        setmachineId(response.data[0].machineid);
        setcomponentId(response.data[0].componentId);
        setreportName(response.data[0].name);
        setreportComment(response.data[0].comment);
        setselectedValue(response.data[0].action);
        if (response.data[0].action === "Action required") {
          setbgV("bg-danger");
          setselectedValue("Action required");
        } else if (response.data[0].action === "No Action required") {
          setbgV("bg-success");
          setselectedValue("No Action required".toUpperCase());
        } else if (response.data[0].action === "Monitor component") {
          setbgV("bg-warning");
          setselectedValue("Monitor component".toUpperCase());
        } else {
          setbgV("bg-info");
          setselectedValue("Waiting for action".toUpperCase());
        }
      }

      // const newArray = response.data.reduce(
      //   (accumulator, currentValue, currentIndex) => {
      //     const subIndex = Math.floor(currentIndex / 15);
      //     if (!accumulator[subIndex]) {
      //       accumulator[subIndex] = [];
      //     }
      //     accumulator[subIndex].push(currentValue);
      //     return accumulator;
      //   },
      //   []
      // );

      //setshowdetail(newArray);
    });
  };

  const get_all_sample = async (d) => {
    const id = location.state?.reportid;

    Axios.post(url + "?p=get_report_sample", {
      id: id,
    }).then((response) => {
      setallsample(response.data);
      getreportDetails(response.data[0].sampleid);
    });
  };

  const get_all_compare_details = async (d) => {
    const id = d.id;

    Axios.post(url + "?p=get_all_report_info", {
      id: id,
      sample_id: d,
    }).then((response) => {
      setAll_details1(response.data);
    });
  };

  function getreportDetails(sample_id) {
    const id = location.state?.reportid;
    const subTab = [];
    const subTab2 = [];

    Axios.post(url + "?p=get_report_details", {
      id: id,
      sample_id: sample_id,
    }).then((response) => {
      setall_reportDetails(response.data);
      response.data.forEach((element) => {
        if (
          element.analytename.substring(0, 2) === "B " ||
          element.analytename.substring(0, 2) === "Mo" ||
          element.analytename.substring(0, 2) === "Ca" ||
          element.analytename.substring(0, 2) === "Mg" ||
          element.analytename.substring(0, 2) === "Zn" ||
          element.analytename.substring(0, 2) === "P "
        ) {
          subTab.push(element);
        } else {
          subTab2.push(element);
        }
      });
      setreportdata1(subTab);
      setall_reportDetails(subTab2);

      if (response.data) {
        setdatefromDetails(response.data[0]?.DateTime);
      }
    });
  }

  function getreportDetails1(d) {
    const id = d.id;

    Axios.post(url + "?p=get_report_details", {
      id: id,
    }).then((response) => {
      setall_reportDetails1(response.data);
      if (response.data) {
        // setdatefromDetails1(response.data[0]?.DateTime);
      }
    });
  }

  const handleClose = () => {
    setviewExport(false);
    setviewcompare(false);
  };

  useEffect(() => {
    get_all_details();
    get_all_sample();
  }, [refresh]);

  return (
    <>
      <Header_mobile />
      <div>
        <Header />
      </div>
      <Modal show={viewExport} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("Report.1")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <button
              onClick={null}
              id="print-btn"
              className="btn btn-defaut text-dark bg-secondary font-weight-bold float-right mr-10 mt-2"
              data-toggle="modal"
            >
              <SiGmail className="text-danger" size={20} />
              <span className="mt-5 ml-2">{"Mail"}</span>
            </button>
            <button
              onClick={() => {
                handlePrint("repprint");
              }}
              id="print-btn"
              className="btn btn-defaut text-dark bg-secondary font-weight-bold float-right mr-10 mt-2"
              data-toggle="modal"
            >
              <BsFileEarmarkPdfFill className="text-danger" size={20} />
              <span className="ml-2">{t("Report.6")}</span>
            </button>
            <button
              onClick={() => {
                exportToExcel();
              }}
              id="print-btn"
              className="btn btn-defaut text-dark bg-secondary font-weight-bold float-right mr-10 mt-2"
              data-toggle="modal"
            >
              <SiMicrosoftexcel size={20} className="text-success" />
              <span className="ml-2">{t("Report.7")}</span>
            </button>
            <button
              onClick={() => {
                handlePrint();
              }}
              id="print-btn"
              className="btn btn-defaut text-dark bg-secondary font-weight-bold float-right mr-10 mt-2"
              data-toggle="modal"
            >
              <FcPrint size={20} />
              <span className="ml-2">{t("Report.5")}</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Machine.18")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={viewcompare} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("Report compare")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="">Choose the report with which to compare</div>
            <div
              className="ml-5 mr-5 bg-white center-block h-300px overflow-auto"
              align="center"
            >
              <table
                id="customers"
                className="table"
                style={{ minHeight: "40vh", maxHeight: "40vh" }}
              >
                <thead>
                  <tr>
                    <th scope="col" className="">
                      #
                    </th>
                    <th scope="col" className="">
                      Name
                    </th>
                    <th scope="col" className="">
                      Date
                    </th>
                    <th scope="col" className="">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody id="resultdata">
                  {comparedReport ? (
                    comparedReport.map((d, index) => (
                      <tr key={index} id='td"+ d[i].m_id +"' className="data">
                        <td id="tb-assigned-to" className="text-left">
                          {d.id}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          {d.name}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          { }
                          {Moment(d.createddate).format("DD-MM-YYYY")}
                        </td>
                        <td id="tb-created-date" className="text-left">
                          <span
                            className="text-info cursor-pointer"
                            onClick={() => {
                              setvfluideBrand(true);
                              setvfluidType(true);
                              setvfluidGrade(true);
                              setvprocessDate(true);
                              setvequipHours(true);
                              setvfluidHours(true);
                              setvfluidChanges(true);
                              setvfilterChanged(true);
                              setvviscosity100(true);
                              setvviscosity40(true);
                              setvtbn(true);
                              setviso(true);
                              get_all_compare_details(d);
                              getreportDetails1(d);
                              handleClose();
                            }}
                          >
                            Compare
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={11} className="text-center pt-20 fs-16">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Machine.18")}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="overflow-auto mt-sm-5">
        <div
          className="p-5 center-block "
          align="center"
          style={{ minWidth: "700px" }}
        >
          <div className="col-xl-12 center-block" align="center" hidden>
            <div className="col-xl-8 bg-white mt-50 pb-1">
              <button
                onClick={() => {
                  setviewExport(true);
                }}
                id="print-btn"
                className="btn btn-defaut text-white bg-primary font-weight-bold float-right mr-10 mt-2"
                data-toggle="modal"
              >
                {t("Report.1")}
              </button>
            </div>
          </div>
          <div className="col-xl-8 bg-white rounded mt-20 p-0 ">
            <div className="p-5 pers2 text-white w-full">
              <h3>{t("Report.9")}</h3>
            </div>
            <div className="flex d-flex w-100 align-items-center mt-5 h-50px">
              <div className="text-left fs-15 flex-fill">
                <div className="col-xl-12 text-left ml-1 fs-15 ">
                  <strong className="col-xl-5 mt-2">
                    {" "}
                    <span> {t("Report.10")} : </span>
                  </strong>{" "}
                  {reportName}
                </div>
                {all_details1[0] ? (
                  <div className="col-xl-12 text-left ml-1 fs-15 mt-1 text-info mb-2">
                    <strong className="col-xl-5 mt-1">
                      {" "}
                      <span> {t("Report.10")} (2) : </span>
                    </strong>{" "}
                    {all_details1[0].name}
                  </div>
                ) : null}
              </div>
              <div className="px-2">
                <select
                  id="cstm-contact"
                  onChange={(e) => {
                    getreportDetails(e.target.value);
                  }}
                  className="form-control rounded-0 cursor-pointer border-gray-light bg-gray ml-2 w-full p-1 text-sm"
                >
                  {allsample ? (
                    allsample.map((d, index) => (
                      <option key={d.sampleid} value={d.sampleid}>
                        {" "}
                        {d.sampleid}{" "}
                      </option>
                    ))
                  ) : (
                    <option key={0} value={""}></option>
                  )}
                </select>
              </div>
              <div className="px-1" title="Compare report">
                <button
                  onClick={() => {
                    if (all_details1[0]) {
                      setAll_details1([]);
                      setall_reportDetails1([]);
                    } else {
                      setviewcompare(true);
                      getAllreport();
                    }
                  }}
                  className="btn bg-red text-white d-flex"
                >
                  <GoGitCompare className="" size={15} title="Compare report" />
                </button>
              </div>
              <div className="px-1" title="Save changes"></div>
              <div className="px-1" title="Share report">
                <button
                  onClick={() => {
                    setShowShareList(!showShareList);
                  }}
                  className="btn bg-info text-white d-flex"
                >
                  <FaShareAlt size={15} />
                </button>
                {showShareList && (
                  <div
                    className="position-absolute bg-info p-2 "
                    ref={shareRef}
                    style={{
                      zIndex: 20,
                      right: 25,
                      top: 39,
                      width: "220px",
                      alignContent: "center",
                    }}
                  >
                    <div>
                      <button
                        onClick={async () => {
                          await setcomplaintForm(" col-xl-12 ");
                          // await handlePrint("repprint");
                          saveAsPDF();
                          await setcomplaintForm(" col-xl-8 ");
                        }}
                        id="print-btn"
                        className="btn btn-defaut text-dark bg-secondary font-weight-bold float-right mr-1 mt-2"
                        data-toggle="modal"
                      >
                        <BsFileEarmarkPdfFill
                          className="text-danger"
                          size={20}
                        />
                      </button>
                      <button
                        id="print-btn"
                        className="btn btn-defaut text-dark bg-secondary font-weight-bold float-right mr-1 mt-2"
                        data-toggle="modal"
                      >
                        <SiMicrosoftexcel size={20} className="text-success" />
                      </button>
                      <button
                        onClick={async () => {
                          await setcomplaintForm(" col-xl-12 ");
                          handlePrint("repprint");
                          await setcomplaintForm(" col-xl-8 ");
                        }}
                        id="print-btn"
                        className="btn btn-defaut text-dark bg-secondary font-weight-bold float-right mr-1 mt-2"
                      >
                        <FcPrint size={20} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-20 mt-md-0 col-xl-12">
              <div className="flex d-flex col-xl-12">
                <div className="form-group col-xl-6 mt-1 bg-light text-left p-5 m-1 rounded">
                  <h4 className="text-center fw-bold mb-5">
                    Machine informations
                  </h4>

                  <div className="mt-5 d-flex col-xl-12 p-0">
                    <div className="col-xl-12 d-flex">
                      <strong className="col-xl-6 mt-2 p-0 text-nowrap">
                        Customer Name :{" "}
                      </strong>{" "}
                      <div className="col-xl-3 mt-2 text-left p-0 text-nowrap">
                        {all_details[0] ? all_details[0].code : null}
                      </div>
                      <div className="col-xl-3 mt-2 text-info text-left p-0 text-nowrap">
                        {all_details1[0] ? all_details1[0].code : null}
                      </div>
                    </div>
                    <div className="col-xl-1">
                      <span align="right" className="text-gray-dark"></span>
                    </div>
                  </div>
                  <div className="mt-5 d-flex col-xl-12 p-0">
                    <div className="col-xl-12 d-flex">
                      <strong className="col-xl-6 mt-2 p-0 text-nowrap">
                        Customer equipment :{" "}
                      </strong>{" "}
                      <div className="col-xl-3 mt-2 p-0 text-nowrap">
                        {all_details[0] ? all_details[0].internalNumber : null}
                      </div>
                      <div className="col-xl-3 mt-2 text-info p-0 text-nowrap">
                        {all_details1[0]
                          ? all_details1[0].internalNumber
                          : null}
                      </div>
                    </div>
                    <div className="col-xl-1">
                      <span align="right" className="text-gray-dark"></span>
                    </div>
                  </div>
                  <div className="mt-5 d-flex col-xl-12 p-0">
                    <div className="col-xl-12 d-flex">
                      <strong className="col-xl-6 mt-2 p-0 text-nowrap">
                        Manufacturer:{" "}
                      </strong>
                      <div className="col-xl-3 mt-2 p-0 text-nowrap">
                        {all_details[0] ? all_details[0].brand : null}
                      </div>
                      <div className="col-xl-3 mt-2 text-info p-0 text-nowrap">
                        {all_details1[0] ? all_details1[0].brand : null}
                      </div>
                    </div>
                    <div className="col-xl-1"></div>
                  </div>
                  <div className="mt-5 d-flex col-xl-12 p-0">
                    <div className="col-xl-12 d-flex">
                      <strong className="col-xl-6 mt-2 p-0 text-nowrap">
                        Serial Number :{" "}
                      </strong>{" "}
                      <div className="col-xl-3 mt-2 p-0 text-nowrap">
                        {all_details[0] ? all_details[0].serialNumber : null}
                      </div>
                      <div className="col-xl-3 mt-2 text-info p-0 text-nowrap">
                        {all_details1[0] ? all_details1[0].serialNumber : null}
                      </div>
                    </div>
                    <div className="col-xl-1">
                      <span align="right" className="text-gray-dark"></span>
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-12 d-flex p-0 text-nowrap">
                      <strong className="col-xl-6 mt-2">Component : </strong>{" "}
                      <div className="col-xl-3 mt-2 p-0 text-nowrap">
                        {all_details[0] ? all_details[0].componentName : null}
                      </div>
                      <div className="col-xl-3 mt-2 text-info p-0 text-nowrap">
                        {all_details1[0] ? all_details1[0].componentName : null}
                      </div>
                    </div>
                    <div className="col-xl-1">
                      <span align="right" className="text-gray-dark"></span>
                    </div>
                  </div>
                </div>
                <div className="form-group col-xl-6 mt-1 bg-light text-left p-5 m-1 rounded">
                  <h4 className="text-center fw-bold mb-5">
                    Fluid informations
                  </h4>
                  <div className="mt-5 flex d-flex">
                    <div className="col-xl-11 d-flex align-middle text-nowrap p-0">
                      <strong className="col-xl-5 mt-2">Fluid Brand: </strong>
                      {vfluideBrand ? (
                        <div className="mt-2">
                          <span className="mt-2 col-xl-3 text-nowrap p-1">
                            {fluideBrand}
                          </span>
                          {all_details1[0] ? (
                            <span className="mt-2 col-xl-3 text-info text-nowrap p-1">
                              {all_details1[0].fluidBrand}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setfluideBrand(e.target.value)}
                          name="code"
                          value={fluideBrand}
                          className="form-control rounded-0"
                          hidden={vfluideBrand}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-5 mt-2 text-nowrap p-0">
                        Fluid Type :{" "}
                      </strong>
                      {vfluidType ? (
                        <div className=" mt-2">
                          <span className="mt-2 col-xl-4 text-nowrap p-1">
                            {fluidType}
                          </span>
                          {all_details1[0] ? (
                            <span className="mt-2 col-xl-4 text-info text-nowrap p-1">
                              {all_details1[0].fluidType}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setfluidType(e.target.value)}
                          name="code"
                          value={fluidType}
                          className="form-control rounded-0"
                          hidden={vfluidType}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-5 mt-2 text-nowrap p-0">
                        Fluid Grade :{" "}
                      </strong>{" "}
                      {vfluidGrade ? (
                        <div className="mt-2">
                          <span className="mt-2 col-xl-4 text-nowrap p-1">
                            {fluidGrade}
                          </span>
                          {all_details1[0] ? (
                            <span className="mt-2 col-xl-4 text-info text-nowrap p-1">
                              {all_details1[0].fluidGrade}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setfluidGrade(e.target.value)}
                          name="code"
                          value={fluidGrade}
                          className="form-control rounded-0"
                          hidden={vfluidGrade}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex d-flex col-xl-12 pb-10">
                <div className="form-group col-xl-6 mt-1 bg-light text-left p-5 m-1 rounded">
                  <h4 className="text-center fw-bold mb-5">
                    Sample informations
                  </h4>
                  <div className="mt-5 flex d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2 text-nowrap p-0">
                        Sample ID :{" "}
                      </strong>{" "}
                      <div className="col-xl-8 mt-2 d-flex text-nowrap p-1">
                        <span className="col-xl-6 text-nowrap p-1">
                          {all_details[0] ? all_details[0].id : null}
                        </span>
                        {all_details1[0] ? (
                          <span className=" col-xl-6 text-info text-nowrap p-1">
                            {all_details1[0].id}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-xl-1"></div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2 text-nowrap p-0">
                        Sample Date :{" "}
                      </strong>{" "}
                      {vprocessDate ? (
                        <span className="mt-2">
                          <span className="col-xl-6 text-nowrap p-1">
                            {Moment(processDate).isValid()
                              ? Moment(processDate).format("DD-MM-YYYY")
                              : null}
                          </span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info text-nowrap p-1">
                              {Moment(all_details1[0].sampleDate).format(
                                "DD-MM-YYYY"
                              )}
                            </span>
                          ) : null}
                        </span>
                      ) : (
                        <input
                          type="date"
                          onChange={(e) => setprocessDate(e.target.value)}
                          name="code"
                          value={processDate}
                          className="form-control rounded-0"
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <div className="col-xl-4 p-0">
                        <strong className=" mt-2 text-nowrap p-0">
                          Process Date :{" "}
                        </strong>{" "}
                      </div>
                      <div>
                        <span className="col-xl-4 p-1">
                          {Moment(datefromDetails).format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className="col-xl-1">
                      <span align="right" className="text-gray-dark"></span>
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2 text-nowrap p-0">
                        Equipment Hours :{" "}
                      </strong>{" "}
                      {vequipHours ? (
                        <div className="">
                          <span className="mt-2 col-xl-6 text-left text-nowrap p-1">
                            {equipHours}
                          </span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2 text-nowrap p-1">
                              {all_details1[0].equipmentHours}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setequipHours(e.target.value)}
                          name="code"
                          value={equipHours}
                          className="form-control rounded-0"
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2 text-nowrap p-0">
                        Fluid Hours :{" "}
                      </strong>{" "}
                      {vfluidHours ? (
                        <div className="">
                          <span className="mt-2 col-xl-6 text-nowrap p-1">
                            {fluidHours}
                          </span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2 text-nowrap p-1">
                              {all_details1[0].fluidHours}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setfluidHours(e.target.value)}
                          name="code"
                          value={fluidHours}
                          className="form-control rounded-0"
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2 text-nowrap p-0">
                        Fluid Changed :{" "}
                      </strong>{" "}
                      {vfluidChanges ? (
                        <div className="">
                          <span className="mt-2 col-xl-6 text-nowrap p-0">
                            {fluidChanges}
                          </span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2 text-nowrap p-0">
                              {all_details1[0].fluidChanged}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <select
                          id="cstm-contact"
                          onChange={(e) => {
                            setfluidChanges(e.target.value);
                          }}
                          value={fluidChanges}
                          className="form-control rounded-0"
                          hidden={vfluidChanges}
                        >
                          <option key={0} value={"YES"}>
                            {"YES"}
                            <input type="text" />
                          </option>
                          <option key={1} value={"NO"}>
                            {"NO"}
                          </option>
                        </select>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2 text-nowrap p-0">
                        Filter Changed :{" "}
                      </strong>{" "}
                      {vfilterChanged ? (
                        <div className="">
                          <span className="mt-2 col-xl-6 text-nowrap p-0">
                            {filterChanged}
                          </span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2 text-nowrap p-0">
                              {all_details1[0].filterChanged}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <select
                          id="cstm-contact"
                          onChange={(e) => setfilterChanged(e.target.value)}
                          value={filterChanged}
                          className="form-control rounded-0"
                          hidden={vfilterChanged}
                        >
                          <option key={0} value={"YES"}>
                            {"YES"}
                            <input type="text" />
                          </option>
                          <option key={1} value={"NO"}>
                            {"NO"}
                            <input type="text" />
                          </option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group col-xl-6 mt-1 bg-light text-left p-5 m-1 rounded">
                  <h4 className="text-center fw-bold mb-5">
                    Fluid informations
                  </h4>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">
                        Viscosity at 100° :{" "}
                      </strong>
                      {vviscosity100 ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{viscosity100}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].viscosity100c}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setviscosity100(e.target.value)}
                          name="code"
                          value={viscosity100}
                          className="form-control rounded-0"
                          hidden={vviscosity100}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">
                        Viscosity at 40° :{" "}
                      </strong>
                      {vviscosity40 ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{viscosity40}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].viscosity40c}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setviscosity40(e.target.value)}
                          name="code"
                          value={viscosity40}
                          className="form-control rounded-0"
                          hidden={vviscosity40}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">TBN : </strong>{" "}
                      {vtbn ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{tbn}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].tbn}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => settbn(e.target.value)}
                          name="code"
                          value={tbn}
                          className="form-control rounded-0"
                          hidden={vtbn}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">ISO : </strong>{" "}
                      {viso ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{iso}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].iso}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setiso(e.target.value)}
                          name="code"
                          value={iso}
                          className="form-control rounded-0"
                          hidden={viso}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">Soot: </strong>
                      {vsoot ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{soot}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].soot}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setsoot(e.target.value)}
                          name="code"
                          value={soot}
                          className="form-control rounded-0"
                          hidden={vsoot}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">Oxidation : </strong>
                      {voxidatation ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{oxidatation}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].oxidation}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setoxidatation(e.target.value)}
                          name="code"
                          value={oxidatation}
                          className="form-control rounded-0"
                          hidden={voxidatation}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">Nitration : </strong>
                      {vnitration ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{nitration}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].nitration}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setnitration(e.target.value)}
                          name="code"
                          value={nitration}
                          className="form-control rounded-0"
                          hidden={vnitration}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">sulfation: </strong>
                      {vsulfation ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{sulfation}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].sulfaction}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setsulfation(e.target.value)}
                          name="code"
                          value={sulfation}
                          className="form-control rounded-0"
                          hidden={vsulfation}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">Wation : </strong>
                      {vwation ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{wation}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].wation}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setwation(e.target.value)}
                          name="code"
                          value={wation}
                          className="form-control rounded-0"
                          hidden={vwation}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">Antifreeze : </strong>
                      {vantifreeze ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{antifreeze}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].antifreeze}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setantifreeze(e.target.value)}
                          name="code"
                          value={antifreeze}
                          className="form-control rounded-0"
                          hidden={vantifreeze}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">Fuel : </strong>
                      {vfuel ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{fuel}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].fuel}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setfuel(e.target.value)}
                          name="code"
                          value={fuel}
                          className="form-control rounded-0"
                          hidden={vfuel}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-5 d-flex">
                    <div className="col-xl-11 d-flex">
                      <strong className="col-xl-4 mt-2">PFC : </strong>
                      {vpfc ? (
                        <div className="d-flex col-xl-8">
                          <span className="mt-2 col-xl-6">{pfc}</span>
                          {all_details1[0] ? (
                            <span className=" col-xl-6 text-info mt-2">
                              {all_details1[0].PFC}
                            </span>
                          ) : null}
                        </div>
                      ) : (
                        <input
                          id="site-name"
                          onChange={(e) => setpfc(e.target.value)}
                          name="code"
                          value={pfc}
                          className="form-control rounded-0"
                          hidden={vpfc}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-xl-12 mt-1 bg-light text-left p-5 m-1 rounded">
                <h4 className="text-center fw-bold mb-5">Add Comment</h4>

                <div className="d-flex">
                  <button
                    onClick={() => {}}
                    className={`btn mb-2  text-white text-left w-200px mr-1 rounded-0 h-40px ${bgV}`}
                    align="left"
                  >
                    {selectedValue}
                  </button>
                </div>
                <textarea
                  value={reportComment}
                  onChange={(e) => {
                    setreportComment(e.target.value);
                  }}
                  className="w-full col-xl-12 max-h-300px min-h150px h-200px p-2 "
                />
              </div> */}
            </div>
          </div>

          <div className={""}>
            <div
              className={
                "bg-white  mt-5 mb-18 min-h-650px mb-10px overflow-auto p-5 overflow-hidden  " +
                complaintForm
              }
              id="divToCapture"
              ref={componentRef}
            >
              <div className="bg-white p-1">
                <div className="fixed"><img
                  alt="Logo"
                  src={head}
                  className="logo-default max-h-140px w-100"
                /> </div>
                <div className="flex d-flex bg-white ">
                  <div className="col-xl-4 print-element1 text-left p-2 mx-auto rounded bg-white">
                    <div className="d-flex align-items-center justify-content-center bg-gray11  h-100">
                      <div className="col-xl-11 d-flex flex-column justify-content-center p-0 ">
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Customer Name
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0] ? all_details[0].code : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Customer equipment
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0]
                              ? all_details[0].internalNumber
                              : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Manufacturer
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0] ? all_details[0].brand : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Model
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0] ? all_details[0].model : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Serial Number
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0]
                              ? all_details[0].serialNumber
                              : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Component
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0]
                              ? all_details[0].componentName
                              : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 print-element1 text-left p-2 mx-auto rounded bg-white">
                    <div className="d-flex  justify-content-center bg-gray11 h-100">
                      <div className="col-xl-11 d-flex flex-column justify-content-center p-0">
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Fluid Brand
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {fluideBrand ? fluideBrand : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Fluid Type
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {fluidType ? fluidType : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Fluid Grade
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-0">
                            {fluidGrade ? fluidGrade : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4  print-element1 text-left p-2 mx-auto rounded bg-white">
                    <div className="d-flex align-items-center justify-content-center bg-gray11 h-full">
                      <div className="col-xl-11 d-flex flex-column justify-content-center p-0">
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Sample ID
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0] ? all_details[0].id : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Sample Date
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {all_details[0] ? all_details[0].id : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Process Date
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-0">
                            {datefromDetails
                              ? Moment(datefromDetails).format("DD-MM-YYYY")
                              : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Equipment Hours
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {equipHours ? equipHours : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Fluid Hours
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {fluidHours ? fluidHours : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Fluid Changed
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-1">
                            {fluidChanges ? fluidChanges : null}
                          </span>
                        </div>
                        <div className="d-flex flex-row p-0">
                          <span className="text-nowrap w-100 fs-11 col-8 p-1">
                            Filter Changed
                          </span>
                          <span className="text-nowrap w-100 fs-11 p-0">
                            {filterChanged ? filterChanged : null}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div align="left" className="w-100 flex d-flex my-2">
                  <div className="col-xl-12 text-left bg-light2 p-3 mt-2 col-12 flex d-flex">
                    <button
                      className={`btn mb-2 ml-1 text-nowrap text-white text-left w-200px mr-1 fs-12 rounded-0 h-40px ${bgV}`}
                      align="left"
                      style={{ fontSize: "12px" }}
                    >
                      {selectedValue}
                    </button>
                    <div className="col-10 rive fs-14">
                      {all_details[0] ? all_details[0].comment : null}
                    </div>
                    {/* {all_details[0].comment ? all_details[0].comment : null} */}
                  </div>
                </div>
                <div className="flex d-flex">
                  <div className="col-xl-6">
                    <table
                      id="customers"
                      className="w-100 bg-white"
                      style={{ minHeight: "40vh", maxHeight: "40vh" }}
                    >
                      <thead>
                        <tr className="" style={{ backgroundColor: "#FFFF00" }}>
                          <th scope="col" className="p-4">
                            Wear Metals(ppm)
                          </th>
                          <th scope="col" className="w-25px p-4">
                            Value
                          </th>
                          <th scope="col" className={"p-4"}>
                            {all_reportDetails1.length !== 0 ? "Value 2" : ""}
                          </th>
                        </tr>
                      </thead>
                      <tbody id="resultdata">
                        {all_reportDetails ? (
                          all_reportDetails.map((d, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 1 ? "data bg-light2" : "data"
                              }
                            >
                              <td id="tb-assigned-to" className="text-left p-1 fs-14">
                                {d.analytename.substring(0, 2)
                                  ? mytb
                                    .filter(
                                      (item) =>
                                        item.symbole ===
                                        d.analytename
                                          .substring(0, 2)
                                          .replace(/\s/g, "")
                                    )
                                    .map((item) => item.nom)
                                  : null}
                                {/* {d.analytename.substring(0, 2)} */}
                              </td>
                              <td id="tb" className="text-left p-3">
                                {d.ConcInCalibUnits < 0
                                  ? "0"
                                  : d.ConcInCalibUnits}
                              </td>
                              <td id="t" className="text-left text-info p-3">
                                {all_reportDetails1.length !== 0
                                  ? all_reportDetails1[index].ConcInCalibUnits
                                  : null}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={11}
                              className="text-center pt-20 fs-16"
                            >
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <table id="customers" className="w-100 bg-white mt-16">
                      <thead>
                        <tr className="" style={{ backgroundColor: "#FFFF00" }}>
                          <th scope="col" className="p-4">
                            Additives(ppm)
                          </th>
                          <th scope="col" className="w-25px p-4 text-center">
                            Value
                          </th>
                          <th scope="col" className={"p-4"}>
                            {all_reportDetails1.length !== 0 ? "Value 2" : ""}
                          </th>
                        </tr>
                      </thead>
                      <tbody id="resultdata">
                        {reportdata1 ? (
                          reportdata1.map((d, index) => (
                            <tr
                              key={index}
                              className={
                                index % 2 === 1 ? "data bg-light2 p-3" : "data"
                              }
                            >
                              <td id="tb-assigned-to" className="text-left p-1 fs-14">
                                {d.analytename.substring(0, 2)
                                  ? mytb
                                    .filter(
                                      (item) =>
                                        item.symbole ===
                                        d.analytename
                                          .substring(0, 2)
                                          .replace(/\s/g, "")
                                    )
                                    .map((item) => item.nom)
                                  : null}
                              </td>
                              <td id="tb" className="text-left p-3">
                                {d.ConcInCalibUnits < 0
                                  ? "0"
                                  : d.ConcInCalibUnits}
                              </td>
                              <td id="t" className="text-left text-info p-3">
                                {all_reportDetails1.length !== 0
                                  ? all_reportDetails1[index].ConcInCalibUnits
                                  : null}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={11}
                              className="text-center pt-20 fs-16"
                            >
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className=" mr-5 bg-white center-block col-xl-6"
                    align="left"
                  >


                    <table
                      id="customers"
                      className=" pt-10 w-100 bg-white "
                    >
                      <thead>
                        <tr className="" style={{ backgroundColor: "#FFFF00" }}>
                          <th scope="col" className="p-4">
                            Oil Condition/Particle Count(ct/ml)
                          </th>
                          <th scope="col" className="w-25px p-4">
                            Value
                          </th>
                          <th scope="col" className={""}>
                            {all_reportDetails1.length !== 0 ? "Value 2" : ""}
                          </th>
                        </tr>
                      </thead>
                      <tbody id="resultdata">
                        <tr className="">
                          <td className="text-left p-3 fs-14">Viscosity 100 °C</td>
                          <td className="text-left p-3">{viscosity100}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0]
                              ? all_details1[0].viscosity40c
                              : null}
                          </td>
                        </tr>
                        <tr className="bg-light2">
                          <td className="text-left p-3 fs-14">Viscosity 40°C</td>
                          <td className="text-left p-3">{viscosity40}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0]
                              ? all_details1[0].viscosity40c
                              : null}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="text-left p-3 fs-14">ISO</td>
                          <td className="text-left p-3">{iso}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].iso : null}
                          </td>
                        </tr>

                        <tr className="bg-light2">
                          <td className="text-left p-3 fs-14">Soot</td>
                          <td className="text-left p-3">{soot}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].soot : null}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="text-left p-3 fs-14">Oxidation</td>
                          <td className="text-left p-3">{oxidatation}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].oxidation : null}
                          </td>
                        </tr>
                        <tr className="bg-light2">
                          <td className="text-left p-3 fs-14">Nitration</td>
                          <td className="text-left p-3">{nitration}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].nitration : null}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="text-left p-3 fs-14">Sulfation</td>
                          <td className="text-left p-3">{sulfation}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0]
                              ? all_details1[0].sulfaction
                              : null}
                          </td>
                        </tr>
                        <tr className="bg-light2">
                          <td className="text-left p-3 fs-14">Water</td>
                          <td className="text-left p-3">{wation}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].wation : null}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="text-left p-3 fs-14">Antifreeze</td>
                          <td className="text-left p-3">{antifreeze}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0]
                              ? all_details1[0].antifreeze
                              : null}
                          </td>
                        </tr>
                        <tr className="bg-light2">
                          <td className="text-left p-3 fs-14">Fuel</td>
                          <td className="text-left p-3">{fuel}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].fuel : null}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="text-left p-3 fs-14">PFC</td>
                          <td className="text-left p-3">{pfc}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].pfc : null}
                          </td>
                        </tr>

                        <tr className="bg-light2">
                          <td className="text-left p-3 fs-14">TBN</td>
                          <td className="text-left p-3">{tbn}</td>
                          <td className="text-left text-info p-3">
                            {all_details1[0] ? all_details1[0].tbn : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>


                </div>
                <div className="flex d-flex mt-50">
                  <div className="col-xl-6 mt-5"></div>
                  <div className="col-xl-6 mt-5"></div>
                </div>
                <div className="fixed"><img
                  alt="Logo"
                  src={foot}
                  className="logo-default max-h-100px w-100"
                /> </div>

                <div className="flex d-flex mt-50 bg-white">
                  <div
                    className=" mr-5 bg-white  col-xl-6 mt-5 "
                    align="left"
                  ></div>
                  <div
                    className=" mr-5  col-xl-6 mt-5  bg-white"
                    align="left"
                  ></div>
                </div>
                <div align="right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Report;
