import { Navigate, Outlet, useNavigate } from "react-router-dom";
import React from "react";

const useAuth = () => {
  const username = sessionStorage.getItem("accessToken");
  var auth = false;

  if (username) {
    auth = true;
  }

  const user = { authenticated: auth };
  return user.authenticated;
};

const Authenticated = () => {
  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default Authenticated;
