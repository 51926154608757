import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { MDBCheckbox } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import toast from "react-hot-toast";
import Header_mobile from "./header_mobile";
import Main from "../Main";

function Add_contact() {
  //Variable part
  const { t } = useTranslation();

  const [cntct_name, setCntct_name] = useState("");
  const [messagename, setMessagename] = useState("");
  const [cntct_phone, setCntct_phone] = useState("");
  const [messagePhone, setMessagePhone] = useState("");
  const [cntct_whatsapp_phone, setCntct_whatsapp_phone] = useState("");
  const [site, setsite] = useState("");
  const [cntct_mail, setCntct_mail] = useState("");
  const [messageMail, setMessageMail] = useState("");
  const [check_phone, setCheck_phone] = useState(false);
  const [allsite, setallsite] = useState([]);
  const [allposition, setallposition] = useState([]);
  const [position, setposition] = useState([]);
  const url = process.env.REACT_APP_BASEURL;
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const check_verif = () => {
    if (check_phone === false) {
      setMessageMail("");
      setCntct_whatsapp_phone(cntct_phone);
      setCheck_phone(true);
      setIsInputDisabled(true);
    } else {
      setCntct_whatsapp_phone("");
      setCheck_phone(false);
      setIsInputDisabled(false);
    }
  };

  const [verif_all_data, setVerif_all_data] = useState(1);

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const add_contact = () => {
    setVerif_all_data(0);
    if (cntct_phone === "") {
      setMessagePhone("Please enter the phone number.");
      setVerif_all_data(1);
    } else {
      setMessagePhone("");
    }

    if (cntct_name === "") {
      setMessagename("Please enter the name.");
      setVerif_all_data(1);
    } else {
      setMessagename("");
    }

    //if (messagePhone != "" && messagename != "") {
    if (cntct_phone !== "" && cntct_name !== "")
      Axios.post(url + "?p=add_contact", {
        name: cntct_name.toUpperCase(),
        phone: cntct_phone,
        w_phone: cntct_whatsapp_phone,
        email: cntct_mail,
        site: site,
        position: position,
      }).then((response) => {
        if (response.data.type_action) {
          notifySuccess("New contact has been added");
          setCntct_mail("");
          setCntct_phone("");
          setCntct_whatsapp_phone("");
          setCntct_name("");
          setVerif_all_data(1);
          setsite("");
          setposition("");
          setCheck_phone(false);
        } else {
          notifyError("Somrthing wrong");
        }
      });
  };

  function getAll_site() {
    Axios.post(url + "?p=getAllSite").then((response) => {
      setallsite(response.data);
    });
  }
  function getAll_position() {
    Axios.post(url + "?p=getAllpostion").then((response) => {
      setallposition(response.data);
    });
  }
  useEffect(() => {
    getAll_site();
    getAll_position();
  }, []);
  //Actions part

  //Visual part
  return (
    <Main>
      <Header />
      <Header_mobile />

      <div className="mt-50 col-xl-12 center-block" align="center">
        <div className="col-xl-3 bg-white rounded">
          <div className="pt-50">
            <h3>{t("Add_contact.1")}</h3>
          </div>
          <form onSubmit={add_contact}>
            <div className="form-group col-xl-12 mt-50">
              <div className="" align="left">
                <label className="font-weight-bold" align="left">
                  {t("Add_site.2")}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="cnct-phone"
                onChange={(e) => setCntct_name(e.target.value)}
                name="code"
                value={cntct_name}
                className="form-control rounded-0"
              />
              <div id="cnct-phone-error" className="text-danger pt-2">
                {messagename}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <div className="" align="left">
                <label className="font-weight-bold" align="left">
                  {t("Add_contact.2")}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="cnct-phone"
                onChange={(e) => setCntct_phone(e.target.value)}
                name="code"
                value={cntct_phone}
                className="form-control rounded-0"
              />
              <div id="cnct-phone-error" className="text-danger pt-2">
                {messagePhone}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12 row">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_contact.3")}
                  <span className="text-danger pl-3"></span>
                </label>
              </div>
              <MDBCheckbox
                name="flexCheck"
                onChange={check_verif}
                value={check_phone}
                id="flexCheckDefault"
                label={t("Add_site.6")}
              />
              <input
                disabled={isInputDisabled}
                id="cstm-name"
                onChange={(e) => setCntct_whatsapp_phone(e.target.value)}
                name="name"
                value={cntct_whatsapp_phone}
                className="form-control rounded-0"
              />
              <div id="cstm-name-error" className="text-danger pt-2">
                { }
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_contact.4")}
                  <span className="text-danger pl-3"></span>
                </label>
                <input
                  id="cstm-username"
                  onChange={(e) => setCntct_mail(e.target.value)}
                  name="username"
                  value={cntct_mail}
                  className="form-control rounded-0"
                />
              </div>
              <div id="cstm-username-error" className="text-danger pt-2">
                {messageMail}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_contact.7")}
                  <span className="text-danger pl-3"></span>
                </label>
                <select
                  id="cstm-contact"
                  onChange={(e) => { setsite(e.target.value); }}
                  value={site}
                  name="contact"
                  className="form-control rounded-0"
                >
                  <option key={0} value={0}>
                    {" "}
                  </option>
                  {allsite.length
                    ? allsite.map((d) => (
                      <option key={d.id} value={d.id}>
                        {" "}
                        {d.siteName}{" "}
                      </option>
                    ))
                    : null}
                </select>
              </div>
              <div id="cstm-username-error" className="text-danger pt-2">
                {messageMail}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Report.19")}
                  <span className="text-danger pl-3"></span>
                </label>
                <select
                  id="cstm-contact"
                  onChange={(e) => setposition(e.target.value)}
                  value={position}
                  name="contact"
                  className="form-control rounded-0"
                >
                  <option key={0} value={0}>
                    {" "}
                  </option>
                  {allposition.length
                    ? allposition.map((d) => (
                      <option key={d.id_position} value={d.id_position}>
                        {" "}
                        {d.positionName}{" "}
                      </option>
                    ))
                    : null}
                </select>
              </div>
              <div id="cstm-username-error" className="text-danger pt-2">
                {messageMail}
              </div>
            </div>
          </form>
          <button onClick={add_contact} className="btn mb-4 bg-red col-xl-12 ">
            {" "}
            {t("Add_contact.5")}
          </button>
        </div>
      </div>

      <Footer />
    </Main>
  );
}

export default Add_contact;
