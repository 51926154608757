import React, { useState, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { MDBCheckbox } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Header_mobile from "./header_mobile";
import Main from "../Main";

function Add_site() {
  //Variable part
  const { t } = useTranslation();

  const [siteName, setsiteName] = useState("");
  const [messagename, setMessagename] = useState("");
  const [contact, setcontact] = useState("");
  const [all_contact, setall_contact] = useState([]);
  const url = process.env.REACT_APP_BASEURL;

  const [verif_all_data, setVerif_all_data] = useState(1);
  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const add_site = () => {
    setVerif_all_data(0);

    if (siteName === "") {
      setMessagename("Please enter the name.");
      setVerif_all_data(1);
    } else {
      setMessagename("");
    }

    //if (messagePhone != "" && messagename != "") {
    if (siteName !== "")
      Axios.post(url + "?p=add_site", {
        name: siteName,
        contact: contact,
      }).then((response) => {
        if (response.data.type_action) {
          notifySuccess("New Site has been added");
          setsiteName("");
          setcontact(0);
          setVerif_all_data(1);
        } else {
          notifyError("Somrthing wrong");
        }
      });
  };

  function getAll_contact() {
    Axios.post(url + "?p=get_customer").then((response) => {
      setall_contact(response.data);
    });
  }
  useEffect(() => {
    getAll_contact();
  }, []);
  //Actions part

  //Visual part
  return (
    <Main>
      <Header />
      <Header_mobile />

      <div className="mt-80  col-xl-12 center-block" align="center">
        <div className="col-xl-3 bg-white rounded">
          <div className="pt-50">
            <h3>{t("Add_site.1")}</h3>
          </div>
          <form onSubmit={add_site}>
            <div className="form-group col-xl-12 mt-50">
              <div className="" align="left">
                <label className="font-weight-bold" align="left">
                  {t("Add_site.2")}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="site-name"
                onChange={(e) => setsiteName(e.target.value)}
                name="code"
                value={siteName}
                className="form-control rounded-0"
              />
              <div id="cnct-phone-error" className="text-danger pt-2">
                {messagename}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_site.3")}
                  <span className="text-danger pl-3"></span>
                </label>
                <select
                  id="cstm-contact"
                  onChange={(e) => setcontact(e.target.value)}
                  value={contact}
                  name="contact"
                  className="form-control rounded-0"
                >
                  <option key={0} value={0}>
                    {" "}
                  </option>
                  {all_contact.length
                    ? all_contact.map((d) => (
                      <option key={d.id} value={d.id}>
                        {" "}
                        {d.code}
                      </option>
                    ))
                    : null}
                </select>
              </div>
            </div>
          </form>
          <button onClick={add_site} className="btn mb-20 bg-red col-xl-12 ">
            {" "}
            {t("Add_site.5")}
          </button>
        </div>
      </div>

      <Footer />
    </Main>
  );
}

export default Add_site;
