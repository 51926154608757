import { Route, Routes, useNavigate } from "react-router-dom";
import Contact_list from "./components/Contact_list";
import Authentication from "../Authentication";
import Report from "./components/Report";
import Add_site from "./components/Add_site";
import Site_list from "./components/Site_list";
import Home from "./components/Home";
import Add_customer from "./components/Add_customer";
import Add_contact from "./components/Add_contact";
import Add_machine from "./components/Add_machine";
import Machine_list from "./components/Machine_list";
import Customer_list from "./components/Customer_list";
import Machine_descriptors from "./components/Machine_descriptors";

export default function AdminRoutes() {
  return (
    <Routes>
      <Route element={<Authentication isAdmin={true} />}>
        <Route exact path="/admin/" element={<Home />} />
        <Route exact path="/admin/home" element={<Home />} />
        <Route
          exact
          path="/admin/add_customer"
          element={<Add_customer type={1} />}
        />
        <Route
          exact
          path="/admin/update_customer/:idCustomer"
          element={<Add_customer type={2} />}
        />
        <Route exact path="/admin/add_contact" element={<Add_contact />} />
        <Route exact path="/admin/customer_list" element={<Customer_list />} />
        <Route exact path="/admin/contact_list" element={<Contact_list />} />
        <Route exact path="/admin/add_machine" element={<Add_machine />} />
        <Route exact path="/admin/machine_list" element={<Machine_list />} />
        <Route
          exact
          path="/admin/machine_descriptors"
          element={<Machine_descriptors />}
        />
        <Route exact path="/admin/report" element={<Report />} />
        <Route exact path="/admin/add_site" element={<Add_site />} />
        <Route exact path="/admin/site_list" element={<Site_list />} />
      </Route>
    </Routes>
  );
}
