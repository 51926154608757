import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./admin/assets/img/auto-logo-circle.png";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { decodeToken } from "./admin/utils/useRefresh";

function Login() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const url = process.env.REACT_APP_BASEURL;

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    Axios.post(url + "credential/login", {
      username,
      password,
    })
      .then((response) => {
        const { status, data } = response;
        if (status === 200 && data) {
          const { status, accessToken, refreshToken, err } = data;
          if (status) {
            sessionStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            const { admin } = decodeToken("accessToken", "session");
            if (admin) navigate("/admin/");
            else {
              navigate("/client/");
            }
          } else {
            setErrorMessage(err);
          }
        } else {
          setErrorMessage(response.data.errMessage);
        }
      })
      .catch((err) => {
      });
  };

  return (
    <div
      className="mt-auto bg-white"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <div
        className="d-flex flex-column w-100 flex-root"
        style={{
          margin: 0,
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <div className="login login-2 d-flex flex-row-fluid" id="kt_login">
          <div className="d-flex flex-center flex-row-fluid p-0">
            <div className="login-form text-center position-relative overflow-hidden">
              <div className="d-flex flex-center mb-3">
                <a href="">
                  <img src={logo} className="max-h-150px" alt="" />
                </a>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="login-signin pb-5 pt-3">
                  <div className="mb-3">
                    <h2>{t("Sign_in.1")}</h2>
                  </div>
                  <div className="mb-8 mt-5">
                    <div className="text-muted fs-11-5">{t("Sign_in.2")}</div>
                  </div>
                  <div id="login-form">
                    <div className="form-group mb-5">
                      <input
                        type="text"
                        name="username"
                        id="username"
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5 min-w-100"
                        required
                        placeholder={t("Sign_in.3")}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group mb-4">
                      <input
                        type="password"
                        name="password"
                        id="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5"
                        placeholder={t("Sign_in.4")}
                        autoComplete="off"
                      />
                    </div>
                    <p id="error-text" className="text-danger pt-0">
                      {errorMessage}
                    </p>
                    <div className="form-group d-flex justify-content-between align-items-center mt-6">
                      <div className="checkbox-inline mr-20 pl-0">
                        <label className="checkbox m-0 text-muted min-w-250px">
                          <input
                            type="checkbox"
                            name="remember"
                            id="remember-me"
                          />
                          <span></span>
                          {t("Sign_in.5")}
                        </label>
                      </div>
                      <span></span>
                    </div>
                    <div className="align-items-center mb-8">
                      <a
                        href="#"
                        id=""
                        className="text-muted text-hover-primary"
                      >
                        {t("Sign_in.6")}
                      </a>
                    </div>
                    <button
                      type="submit"
                      id="check-user"
                      className="btn btn-lg text-white rounded-0 btn-block fs-15-5 bg-red"
                      name="btn-connexion"
                    >
                      {t("Sign_in.1")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
