import Header from "./header";
import Footer from "./footer";
import { useAsyncError, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Header_mobile from "./header_mobile";
import { useTranslation } from "react-i18next";
import Main from "../Main";

function Home() {
  const { t } = useTranslation();
  return (
    <Main>
      <Header_mobile />
      <Header />
      <div
        className="d-flex flex-column flex-column-fluid"
        style={{ minHeight: "85vh" }}
      >
        <div className=" container mt-20 mb-20">
          <div className="row mb-5">
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
              <div className="row">
                <div className="col-xl-3 col-md-4" id="tck1">
                  <div className="card card-custom gutter-b">
                    <div className="card-body d-flex flex-column pb-0 ">
                      <div className="flex-grow-1 pb-0">
                        <div className="pr-2 mb-0 pt-3">
                          <h1
                            id="totTicket-number"
                            className="text-dark-75 text-left"
                          >
                            0
                          </h1>
                          <div className="fs-17 text-left h-80px">
                            {t("Home.1")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-4">
                  <div className="card card-custom gutter-b">
                    <div className="card-body d-flex flex-column pb-0 ">
                      <div className="flex-grow-1 pb-0">
                        <div className="pr-2 mb-0 pt-3">
                          <h1
                            id="openedTicket-number-assign"
                            className="text-dark-75 text-left"
                          >
                            1
                          </h1>
                          <div className="fs-17 h-80px text-left">
                            {t("Home.2")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-4">
                  <div className="card card-custom gutter-b">
                    <div className="card-body d-flex flex-column pb-0 ">
                      <div className="flex-grow-1 pb-0">
                        <div className="pr-2 mb-0 pt-3">
                          <h1
                            id="openedTicket-assign"
                            className="text-dark-75 text-left"
                          >
                            2
                          </h1>
                          <div className="fs-17 h-80px text-left">
                            {t("Home.3")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-4" id="tck3">
                  <div className="card card-custom gutter-b">
                    <div className="card-body d-flex flex-column pb-0 ">
                      <div className="flex-grow-1 pb-0">
                        <div className="pr-2 mb-0 pt-3">
                          <h1
                            id="number-tickets-on-wait"
                            className="text-dark-75 text-left"
                          >
                            3
                          </h1>
                          <div className="fs-17 h-80px text-left">
                            {t("Home.4")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Main>
  );
}

export default Home;
