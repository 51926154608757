import Header from "./header";
import Footer from "./footer";
import { useState, useEffect, useLayoutEffect } from "react";
import Header_mobile from "./header_mobile";
import Moment from "moment";
import { useNavigate } from "react-router-dom";

import Axios from "axios";
import { checkTokenExpiration } from "../services/useRefresh";
import { useTranslation } from "react-i18next";
import jwt from "jwt-decode";
import Card from "./Homecard";

function Home() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const url = process.env.REACT_APP_BASEURL;
  const [all_machine, setall_machine] = useState(0);
  const [all_report, setall_report] = useState([]);
  const [lastReport, setlastReport] = useState([]);
  const user = jwt(sessionStorage.getItem("accessToken"));

  const getCustomerMachine = async () => {
    const config = await checkTokenExpiration();
    Axios.post(
      `${url}client/get_customer_machine`,
      {
        id: user.id,
      },
      config
    ).then((response) => {
      setall_machine(response.data.length);
    });
  };

  const get_allreport = async () => {
    const config = await checkTokenExpiration();
    Axios.post(
      `${url}client/get_report`,
      {
        id: user.id,
      },
      config
    ).then((response) => {
      setall_report(response.data);
      setlastReport(response.data.slice(0, 5));
    });
  };

  useEffect(() => {
    getCustomerMachine();
    get_allreport();
  }, []);

  return (
    <>
      <Header_mobile />
      <Header />
      <div
        className="d-flex flex-column flex-column-fluid mt-sm-10"
        style={{ minHeight: "85vh" }}
      >
        <div className=" container mt-20 mb-20">
          <div className="row mb-5">
            <div className="col-xl-1"></div>
            <div className="col-xl-10">
              <div className="row">
                <Card
                  value={all_machine}
                  title={t("Machine.21")}
                  color={"danger"}
                />
                <Card
                  value={all_report.length}
                  title={t("Report.13")}
                  color={"warning"}
                />
                <Card
                  title={t("Machine.22")}
                  value={
                    all_report[0] ? (
                      <span>{all_report[0] ? all_report[0].name : "-"}</span>
                    ) : (
                      ""
                    )
                  }
                  color={"info"}
                />
                <Card
                  value={
                    all_report[0] ? (
                      <div>On :{all_report[0].internalNumber}</div>
                    ) : (
                      ""
                    )
                  }
                  title={t("Machine.23")}
                  color={"success"}
                />
              </div>

              <div className="timeline-item mt-10 bg-white p-2 min-h-150px ">
                <div className="text-left w-full fs-bold bg-gray">
                  <h3 className="p-2 pers2 text-white">LAST REPORT(S)</h3>
                </div>
                <div className=" max-h-450px overflow-auto">
                  {all_report
                    ? lastReport.map((d, index) => {
                      return (
                        <div
                          className="mt-2 p-5 pers1 text-left "
                          key={index}
                        >
                          <div className="timeline-label fw-bold text-gray-800 fs-6 ml-5 d-flex">
                            <div className="timeline-badge">
                              <i className="fa fa-genderless text-warning fs-1 "></i>
                            </div>
                            <span className="text-danger ml-2">
                              {t("Report.14")}
                            </span>{" "}
                            <div className="ml-2">
                              {d.createddate
                                ? Moment(d.createddate).format("DD-MM-YYYY")
                                : null}
                            </div>
                          </div>
                          <div className="ml-5">
                            <span className="text-danger ml-5">
                              {t("Report.15")}
                            </span>{" "}
                            <span className="fs-bold">{d.name}</span>
                          </div>
                          <div className="ml-5">
                            <span className="text-danger ml-5">
                              {t("Report.16")}
                            </span>{" "}
                            <span className="fs-bold">
                              {d.internalNumber}
                            </span>
                          </div>

                          <div
                            className="text-success underligne text-end cursor-pointer ml-10 mt-2"
                            onClick={() => {
                              navigate("/client/report", {
                                state: {
                                  reportid: d.id,
                                  machineid: d.machineid,
                                },
                              });
                            }}
                          >
                            {t("Report.17")}
                          </div>
                        </div>
                      );
                    })
                    : "No report"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
