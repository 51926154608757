import { useNavigate } from "react-router-dom"
import { decodeToken } from "./utils/useRefresh"
import { useEffect } from "react"


export default function Main({ children }) {
    const navigate = useNavigate()

    useEffect(() => {
        const decoded = () => {
            const decode = decodeToken('accessToken', 'session')
            if (!decode?.admin) {
                navigate('/')
                sessionStorage.clear()
                localStorage.clear()
            }
        }

        decoded()
    }, [])
    return (
        <>
            {children}
        </>
    )
}