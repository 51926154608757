import React from "react";

class TextTransformUtils extends React.Component {
  static toUpperCase(text) {
    return text.toUpperCase();
  }

  static capitalizeFirst(text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  static toLowerCase(text) {
    return text.toLowerCase();
  }

  render() {
    return null; // Cette classe n'a pas besoin de rendre quoi que ce soit
  }
}

export default TextTransformUtils;
