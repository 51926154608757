import Header from "./header";
import Footer from "./footer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Pagination } from "react-bootstrap";
import { BiPlusCircle } from "react-icons/bi";
import Header_mobile from "./header_mobile";
import TextTransformUtils from "../utils/TextTransformUtils";
import Main from "../Main";

function Machine_descriptors(props) {
  //Variable part
  const { t } = useTranslation();
  const [mch_brand, setMch_brand] = useState("");
  const [messageMch_brand, setMessagemch_brand] = useState("");

  const [mch_model, setMch_model] = useState("");
  const [messageMch_model, setMessagemch_model] = useState("");

  const [mch_type, setMch_type] = useState("");
  const [messageMch_type, setMessageMch_type] = useState("");

  const [showBrand, setShowBrand] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showType, setShowType] = useState(false);

  const [showTypeFilter, setShowTypeFilter] = useState(false);
  const [showModelFilter, setShowModelFilter] = useState(false);
  const [showBrandFilter, setShowBrandFilter] = useState(false);

  const [all_brand, setAll_brand] = useState([]);
  const [all_model, setAll_model] = useState([]);
  const [all_type, setAll_type] = useState([]);

  const [all_brand1, setAll_brand1] = useState([]);
  const [all_model1, setAll_model1] = useState([]);
  const [all_type1, setAll_type1] = useState([]);

  const url = process.env.REACT_APP_BASEURL;

  const handleBrandClose = () => {
    setShowBrand(false);
    setMessagemch_brand("");
    setMch_brand("");
  };
  const filtering = (value_type_filter, textFilter) => {
    if (value_type_filter === "Brand") {
      if (textFilter !== "") {
        const filtered = all_brand1.filter((brand) => {
          return brand.brand.toLowerCase().includes(textFilter.toLowerCase());
        });
        setAll_brand(filtered);
      } else {
        setShowBrandFilter(false);
        setAll_brand(all_brand1);
      }
    } else if (value_type_filter === "Model") {
      if (textFilter !== "") {
        const filtered = all_model1.filter((model) => {
          return model.model.toLowerCase().includes(textFilter.toLowerCase());
        });
        setAll_model(filtered);
      } else {
        setShowModelFilter(false);
        setAll_model(all_model1);
      }
    } else if (value_type_filter === "Type") {
      if (textFilter !== "") {
        const filtered = all_type1.filter((type) => {
          return type.type.toLowerCase().includes(textFilter.toLowerCase());
        });
        setAll_type(filtered);
      } else {
        setShowTypeFilter(false);
        setAll_type(all_type1);
      }
    }
  };

  const handleBrandShow = () => setShowBrand(true);

  const handleModelClose = () => {
    setShowModel(false);
    setMch_model("");
    setMessagemch_model("");
  };

  const handleModelShow = () => {
    setShowModel(true);
    setMch_model("");
  };

  const handleTypeClose = () => {
    setShowType(false);
    setMch_type("");
    setMessageMch_type("");
  };
  const handleTypeShow = () => setShowType(true);

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  //Actions part
  const showseen = (brandv) => {
    if (brandv === "Brand" && showBrandFilter === false)
      setShowBrandFilter(true);
    else setShowBrandFilter(false);

    if (brandv === "Model" && showModelFilter === false)
      setShowModelFilter(true);
    else setShowModelFilter(false);

    if (brandv === "Type" && showTypeFilter == false) setShowTypeFilter(true);
    else setShowTypeFilter(false);
  };

  const add_brand = () => {
    if (mch_brand !== "") {
      Axios.post(url + "?p=add_brand", {
        name: mch_brand.toUpperCase(),
      }).then((response) => {
        if (response.data.type_action) {
          notifySuccess("New brand has been added");
          setMch_brand("");
          handleBrandClose();
          get_all_();
        } else {
          setMessagemch_brand(
            t("Machine_descriptors.10") + response.data.errorno
          );
        }
      });
    } else {
      setMessagemch_brand(t("Machine_descriptors.9"));
    }
  };

  const add_model = () => {
    if (mch_model !== "") {
      Axios.post(url + "?p=add_model", {
        name: mch_model.toUpperCase(),
      }).then((response) => {
        if (response.data.type_action) {
          notifySuccess("New model has been added");
          //toast.success('Successfully toasted!');
          setMch_model("");
          handleModelClose();
          get_all_();
        } else {
          setMessagemch_model(
            t("Machine_descriptors.10") + response.data.errorno
          );
        }
      });
    } else setMessagemch_model(t("Machine_descriptors.9"));
  };

  const add_type = () => {
    if (mch_type != "") {
      Axios.post(url + "?p=add_type", {
        name: mch_type.toUpperCase(),
      }).then((response) => {
        if (response.data.type_action) {
          notifySuccess("New type has been added");
          //toast.success('Successfully toasted!');
          setMch_type("");
          handleTypeClose();
          get_all_();
        } else {
          setMessageMch_type(
            t("Machine_descriptors.10") + response.data.errorno
          );
        }
      });
    } else setMessageMch_type(t("Machine_descriptors.9"));
  };

  ////////////////////////////////////////////////////PAGINATION BRAND////////////////////////////////////////////////

  const [currentBrnadPage, setcurrentBrnadPage] = useState(1);
  const [lineBrandPerPage, setlineBrandPerPage] = useState(10);

  const lindexOfLastBrandPost = currentBrnadPage * lineBrandPerPage;
  const indexOfFirstBrandPost = lindexOfLastBrandPost - lineBrandPerPage;
  const currentBrandPosts = all_brand.slice(
    indexOfFirstBrandPost,
    lindexOfLastBrandPost
  );

  const PaginationBrand = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 3;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage === totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    //alert(currentBrnadPage)

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginateBrand(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentBrnadPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginateBrand(currentBrnadPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentBrnadPage == number ? (
                  <a
                    onClick={() => paginateBrand(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a
                    onClick={() => paginateBrand(number)}
                    href="#"
                    className=""
                  >
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentBrnadPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginateBrand(currentBrnadPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a
                  onClick={() => paginateBrand(totalPages)}
                  href="#"
                  className=""
                >
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  const paginateBrand = (pageNumber) => setcurrentBrnadPage(pageNumber);

  //////////////////////////////////END PAGINATION BRAND////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////PAGINATION MODEL////////////////////////////////////////////////

  const [currentModelPage, setcurrentModelPage] = useState(1);
  const [lineModelPerPage, setlineModelPerPage] = useState(10);

  const indexOfLastModelPost = currentModelPage * lineModelPerPage;
  const indexOfFirstModelPost = indexOfLastModelPost - lineModelPerPage;
  const currentModelPosts = all_model.slice(
    indexOfFirstModelPost,
    indexOfLastModelPost
  );

  const PaginationModel = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 3;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage == totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginateModel(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentModelPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginateModel(currentModelPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentModelPage == number ? (
                  <a
                    onClick={() => paginateModel(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a
                    onClick={() => paginateModel(number)}
                    href="#"
                    className=""
                  >
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentModelPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginateModel(currentModelPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a
                  onClick={() => paginateModel(totalPages)}
                  href="#"
                  className=""
                >
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  const paginateModel = (pageNumber) => setcurrentModelPage(pageNumber);

  /////////////////////////////////////////////////////END PAGINATION MODEL//////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////PAGINATION TYPE////////////////////////////////////////////////////////////////
  const [currentPage, setCurrentPage] = useState(1);
  const [linePerPage, setlinePerPage] = useState(10);

  const indexOfLastPost = currentPage * linePerPage;
  const indexOfFirstPost = indexOfLastPost - linePerPage;
  const currentPosts = all_type.slice(indexOfFirstPost, indexOfLastPost);

  const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
    var pageNumbers = [];
    var pageslink = 3;
    var startlink = 1;

    var totalPages = Math.ceil(totalPosts / postsPerPage);

    if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
      startlink = currentPage - 2;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 2; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
      startlink = currentPage - 3;
      pageNumbers = [];
      for (let i = startlink; i <= currentPage + 1; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage == totalPages) {
      if (currentPage - 5 >= 0) startlink = currentPage - 4;
      else if (currentPage - 4 >= 0) startlink = currentPage - 3;
      else if (currentPage - 3 >= 0) startlink = currentPage - 2;
      else if (currentPage - 2 >= 0) startlink = currentPage - 1;
      pageNumbers = [];
      for (let i = startlink; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      startlink = 1;
      pageNumbers = [];
      if (pageslink > totalPages) pageslink = totalPages;
      for (let i = startlink; i <= pageslink; i++) {
        pageNumbers.push(i);
      }
    }

    return (
      <>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              {
                <a onClick={() => paginate(1)} href="#" className="">
                  {"«"}
                </a>
              }
            </li>

            <li className="page-item">
              {currentPage == 1 ? (
                <a href="#" className="" disabled>
                  {"<"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage - 1)}
                  href="#"
                  className=""
                >
                  {"<"}
                </a>
              )}
            </li>

            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                {currentPage == number ? (
                  <a
                    onClick={() => paginate(number)}
                    href="#"
                    className="bg-red"
                  >
                    {number}
                  </a>
                ) : (
                  <a onClick={() => paginate(number)} href="#" className="">
                    {number}
                  </a>
                )}
              </li>
            ))}

            <li className="page-item">
              {currentPage == totalPages ? (
                <a href="#" className="" disabled>
                  {">"}
                </a>
              ) : (
                <a
                  onClick={() => paginate(currentPage + 1)}
                  href="#"
                  className=""
                >
                  {">"}
                </a>
              )}
            </li>

            <li className="page-item">
              {
                <a onClick={() => paginate(totalPages)} href="#" className="">
                  {"»"}
                </a>
              }
            </li>
          </ul>
        </nav>
      </>
    );
  };

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const load_data = () => {
    Axios.post(url + "?p=get_brand").then((response) => {
      setAll_brand(response.data);
      setAll_brand1(response.data);
    });
    Axios.post(url + "?p=get_model").then((response) => {
      setAll_model(response.data);
      setAll_model1(response.data);
    });
    Axios.post(url + "?p=get_type").then((response) => {
      setAll_type(response.data);
      setAll_type1(response.data);
    });
  };

  useEffect(() => {
    load_data();
  }, []);

  const get_all_ = () => {
    Axios.post(url + "?p=get_brand").then((response) => {
      setAll_brand(response.data);
      setAll_brand1(response.data);
    });
    Axios.post(url + "?p=get_model").then((response) => {
      setAll_model(response.data);
    });
    Axios.post(url + "?p=get_type").then((response) => {
      setAll_type(response.data);
    });
  };

  //Visual part
  return (
    <Main>
      <Modal show={showBrand} onHide={handleBrandClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Machine_descriptors.5")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 mt-55">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine_descriptors.4")}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="cstm-code"
                onChange={(e) => setMch_brand(e.target.value)}
                name="code"
                value={mch_brand}
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {messageMch_brand}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleBrandClose}>
            {t("Machine_descriptors.8")}
          </Button>
          <Button variant="primary" onClick={add_brand} className="btn bg-red ">
            {t("Machine_descriptors.5")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModel} onHide={handleModelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Machine_descriptors.6")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 ">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine_descriptors.4")}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="cstm-code"
                onChange={(e) => setMch_model(e.target.value)}
                name="code"
                value={mch_model}
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {messageMch_model}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModelClose}>
            {t("Machine_descriptors.8")}
          </Button>
          <Button variant="primary" onClick={add_model}>
            {t("Machine_descriptors.6")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showType} onHide={handleTypeClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("Machine_descriptors.7")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 ">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Machine_descriptors.4")}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="cstm-code"
                onChange={(e) => setMch_type(e.target.value)}
                name="code"
                value={mch_type}
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {messageMch_type}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleTypeClose}>
            {t("Machine_descriptors.8")}
          </Button>
          <Button variant="primary" onClick={add_type}>
            {t("Machine_descriptors.7")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Header_mobile />
      <Header />
      <div
        className="d-flex flex-column flex-column-fluid"
        style={{ minHeight: "90vh" }}
      >
        <div className=" container mt-10 mb-10">
          <div className="row mb-5">
            <div className="col-xl-12 ">
              <div className="row col-12">
                <div className="col-lg-4 mt-80">
                  <div className="bg-white p-5">
                    <div
                      className="d-flex"
                      align="left"
                      style={{ postion: "relative", height: 30 }}
                    >
                      <h3
                        className="float-left"
                        style={{ position: "absolute", left: 30 }}
                      >
                        {t("Machine_descriptors.1")}
                      </h3>
                      <BiPlusCircle
                        className="float-right text-danger p-0 m-0 display-4 cursor-pointer"
                        style={{ position: "absolute", right: 30, top: 13 }}
                        onClick={handleBrandShow}
                      />
                    </div>
                    <hr />
                    <div
                      className="ml-5 mr-5 bg-white center-block h-300px overflow-auto"
                      align="center"
                    >
                      <table
                        id="customers"
                        className="table"
                        style={{ minHeight: "40vh", maxHeight: "40vh" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col" className="w-25px">
                              #
                            </th>
                            <th scope="col" className="">
                              {t("Machine_descriptors.4")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketidModal"
                                onClick={() => showseen("Brand")}
                              >
                                <i className="la la-filter"></i>
                              </a>
                              {showBrandFilter === true ? (
                                <input
                                  onChange={(e) =>
                                    filtering("Brand", e.target.value)
                                  }
                                  type="text"
                                  className="form-control rounded-0 h-10"
                                ></input>
                              ) : (
                                " "
                              )}
                            </th>
                            <th scope="col" className="w-25px">
                              ACTIVE
                            </th>
                          </tr>
                        </thead>
                        <tbody id="resultdata">
                          {currentBrandPosts ? (
                            currentBrandPosts.map((d, index) => (
                              <tr
                                key={index}
                                id='td"+ d[i].m_id +"'
                                className="data"
                              >
                                <td id="tb-assigned-to" className="text-left">
                                  {d.id}
                                </td>
                                <td id="tb-created-date" className="text-left">
                                  {d.brand}
                                </td>
                                <td id="tb-created-date" className="text-left">
                                  {d.active === 1 ? "Yes" : "No"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={11}
                                className="text-center pt-20 fs-16"
                              >
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card-footer pt-2 pb-2">
                    <div className="d-flex justify-content-center">
                      <PaginationBrand
                        postsPerPage={lineBrandPerPage}
                        totalPosts={all_brand.length}
                        paginateModel={paginateBrand}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  mt-80 pr-1" align="center">
                  <div className=" center-block bg-white p-5" align="center">
                    <div
                      className="d-flex"
                      align="left"
                      style={{ postion: "relative", height: 30 }}
                    >
                      <h3
                        className="float-left"
                        style={{ position: "absolute", left: 30 }}
                      >
                        {t("Machine_descriptors.2")}
                      </h3>
                      <BiPlusCircle
                        className="float-right text-danger p-0 m-0 display-4 cursor-pointer"
                        style={{ position: "absolute", right: 30, top: 13 }}
                        onClick={handleModelShow}
                      />
                    </div>
                    <hr />

                    <div
                      className="ml-5 mr-5 bg-white center-block h-300px overflow-auto"
                      align="center"
                    >
                      <table id="customers" className="table">
                        <thead>
                          <tr>
                            <th scope="col" className="w-25px">
                              #
                            </th>

                            <th scope="col" className="text-left">
                              {t("Machine_descriptors.4")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketidModal"
                                onClick={() => showseen("Model")}
                              >
                                <i className="la la-filter"></i>
                              </a>
                              {showModelFilter === true ? (
                                <input
                                  onChange={(e) =>
                                    filtering("Model", e.target.value)
                                  }
                                  type="text"
                                  className="form-control rounded-0 h-10"
                                ></input>
                              ) : (
                                " "
                              )}
                            </th>

                            <th scope="col" className="text-left">
                              ACTIVE
                            </th>
                          </tr>
                        </thead>
                        <tbody id="resultdata">
                          {currentModelPosts ? (
                            currentModelPosts.map((d, index) => (
                              <tr
                                key={index}
                                id='td"+ d[i].m_id +"'
                                className="data"
                              >
                                <td id="tb-assigned-to" className="text-left">
                                  {d.id}
                                </td>
                                <td id="tb-created-date" className="text-left">
                                  {d.model}
                                </td>
                                <td id="tb-created-date" className="text-left">
                                  {d.active === 1 ? "Yes" : "No"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={11}
                                className="text-center pt-20 fs-16"
                              >
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card-footer pt-2 pb-2">
                    <div className="d-flex justify-content-center">
                      <PaginationModel
                        postsPerPage={lineModelPerPage}
                        totalPosts={all_model.length}
                        paginateModel={paginateModel}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4  mt-80 pr-1 " align="right">
                  <div className="bg-white center-block p-5" align="center">
                    <div
                      className="d-flex"
                      align="left"
                      style={{ postion: "relative", height: 30 }}
                    >
                      <h3
                        className="float-left"
                        style={{ position: "absolute", left: 30 }}
                      >
                        {t("Machine_descriptors.3")}
                      </h3>
                      <BiPlusCircle
                        className="float-right text-danger p-0 m-0 display-4 cursor-pointer"
                        style={{ position: "absolute", right: 30, top: 13 }}
                        onClick={handleTypeShow}
                      />
                    </div>
                    <hr />

                    <div
                      className="ml-5 mr-5 bg-white center-block h-300px overflow-auto"
                      align="center"
                    >
                      <table id="customers" className="table">
                        <thead>
                          <tr>
                            <th scope="col" className="w-25px">
                              #
                            </th>

                            <th scope="col" className="text-left">
                              {t("Machine_descriptors.4")}
                              <a
                                href="#"
                                data-toggle="modal"
                                data-target="#ticketidModal"
                                onClick={() => showseen("Type")}
                              >
                                <i className="la la-filter"></i>
                              </a>
                              {showTypeFilter === true ? (
                                <input
                                  onChange={(e) =>
                                    filtering("Type", e.target.value)
                                  }
                                  type="text"
                                  className="form-control rounded-0 h-10"
                                ></input>
                              ) : (
                                " "
                              )}
                            </th>
                            <th scope="col" className="text-left">
                              ACTIVE
                            </th>
                          </tr>
                        </thead>
                        <tbody id="resultdata">
                          {currentPosts.length ? (
                            currentPosts.map((d, index) => (
                              <tr
                                key={index}
                                id='td"+ d[i].m_id +"'
                                className="data"
                              >
                                <td id="tb-assigned-to" className="text-left">
                                  {d.id}
                                </td>
                                <td id="tb-created-date" className="text-left">
                                  {TextTransformUtils.capitalizeFirst(d.type)}
                                </td>
                                <td id="tb-created-date" className="text-left">
                                  {d.active === 1 ? "Yes" : "No"}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={11}
                                className="text-center pt-20 fs-16"
                              >
                                No data found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card-footer pt-2 pb-2">
                    <div className="d-flex justify-content-center">
                      <Pagination
                        postsPerPage={linePerPage}
                        totalPosts={all_type.length}
                        paginate={paginate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Main>
  );
}

export default Machine_descriptors;
