export default function Card({ title, value, color }) {
  return (
    <div className="col-xl-3 col-md-4 " id="tck1">
      <div
        className={`card card-custom rounded-0 bg-${color} text-white cursor-pointer`}
        onClick={() => {}}
      >
        <div className="card-body d-flex flex-column pb-0 ">
          <div className="flex-grow-1 pb-0">
            <div className="pr-2 mb-0 pt-3">
              <h3
                id="totTicket-number"
                className=" text-left w-200px"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {value}
              </h3>
              <div className="fs-17 text-left h-80px">{title}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
