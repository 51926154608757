import enFlag from "../assets/img/united-states.png";
import frFlag from "../assets/img/france.png";
import arFlag from "../assets/img/lebanon.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function Language(props) {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(frFlag);

  function handleCookie(e) {
    localStorage.setItem("i18nextLng", e, {
      expires: new Date(Date.now() + 360 * 24 * 60 * 60 * 1000),
    });
    i18n.changeLanguage(e);
    handleChangeLanguage(e);
  }

  const handleChangeLanguage = (e) => {
    if (e === "en") {
      setLanguage(enFlag);
    } else if (e === "fr") {
      setLanguage(frFlag);
    } else {
      setLanguage(arFlag);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("i18nextLng") === "en") setLanguage(enFlag);
    else setLanguage(frFlag);
  }, []);

  return (
    <div className="dropdown p-0 m-0 pt-2 ">
      <Dropdown className="p-0 m-0 ">
        <Dropdown.Toggle
          variant="dropdown-basic"
          className="w-auto h-40px text-white p-0 m-0 pt-0 pb-0"
        >
          <div
            className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
            id="kt_quick_user_toggle"
          >
            <img src={language} width={20} height={20} alt="Flag" />
            <span className="text-dark pl-2 font-weight-bolder">
              {props.dataText}
            </span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-180px">
          <Dropdown.Item
            href="#"
            className="navi-link border-bottom"
            onClick={() => handleCookie("en")}
          >
            <img src={enFlag} width="20" height="20" alt="enFlag" />{" "}
            <span className="navi-text font-weight-bolder">
              <span className="symbol symbol-20 mr-1"></span> English
            </span>
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            className="navi-link  border-bottom"
            onClick={() => handleCookie("fr")}
          >
            <img src={frFlag} width="20" height="20" alt="frFlag" />{" "}
            <span className="navi-text font-weight-bolder">
              {" "}
              <span className="symbol symbol-20 mr-1"></span> Francais
            </span>
          </Dropdown.Item>
          <Dropdown.Item
            href="#"
            className="navi-link"
            onClick={() => handleCookie("ar")}
          >
            <img src={arFlag} width="20" height="20" alt="frFlag" />{" "}
            <span className="navi-text font-weight-bolder">
              {" "}
              <span className="symbol symbol-20 mr-1"></span> Arabe
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default Language;
