import { Route, Routes } from "react-router-dom";
import "../App.css";
import "./assets/custom/css/style.bundle.css";
import "./assets/custom/plugins/global/plugins.bundle.css";
import Home from "./components/Home";
import Machine_list from "./components/Machine_list";
import Authentication from "../Authentication";
import Report from "./components/Report";
import Add_machine from "./components/Add_machine";

export default function ClientRoutes() {
  return (
    <Routes>
      <Route element={<Authentication />}>
        <Route exact path="/client/" element={<Home />} />
        <Route exact path="/client/home" element={<Home />} />
        <Route exact path="/client/machine_list" element={<Machine_list />} />
        <Route exact path="/client/report" element={<Report />} />
        <Route exact path="/client/add_machine" element={<Add_machine />} />
      </Route>
    </Routes>
  );
}
