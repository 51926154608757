import Header from "./header";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import Axios from "axios";
import toast from "react-hot-toast";
import Header_mobile from "./header_mobile";
import { IoMdRefreshCircle } from "react-icons/io";
import Main from "../Main";
import { useParams } from "react-router-dom";

function Add_customer(props) {
  //Variable part
  const { t } = useTranslation();
  const { idCustomer } = useParams();

  const [cstm_code, setCstm_code] = useState("");
  const [messageCode, setMessageCode] = useState("");
  const [cstm_name, setCstm_name] = useState("");
  const [messageName, setMessageName] = useState("");
  const [cstm_username, setCstm_username] = useState("");
  const [messageUsername, setMessageUsername] = useState("");
  const [cstm_password, setCstm_password] = useState("");
  const [messagePassword, setMessagePassword] = useState("");
  const [cstm_contact, setCstm_contact] = useState(0);
  const [messageContact, setMessageContact] = useState("");
  const [all_site, setAll_site] = useState([]);
  const [verif_all_data, setVerif_all_data] = useState("");
  const [generated_password, setgenerated_password] = useState("");
  const url = process.env.REACT_APP_BASEURL;

  const [title, settitle] = useState("");

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const getcustomerinfo = () => {
    Axios.post(url + "?p=get_customer_byId", { id_customer: idCustomer }).then(
      (response) => {
        setCstm_code(response.data[0].code);
        setCstm_name(response.data[0].name);
        setCstm_username(response.data[0].username);
        setCstm_password(response.data[0].password);
        setCstm_contact(response.data[0].siteId);
      }
    );
  };
  //const [cstm_active, setCstm_active] = useState('');
  //const [messageActive, setMessageActive] = useState('');
  useEffect(() => {
    if (idCustomer) {
      getcustomerinfo();
    }
    Axios.post(url + "?p=getAllSite").then((response) => {
      setAll_site(response.data);
    });
  }, []);
  //Actions part

  const add_customer = () => {
    setMessageContact("");
    setVerif_all_data(0);
    if (cstm_code === "") {
      setMessageCode("Please enter the phone number.");
      setVerif_all_data(1);
    } else setMessageCode("");

    if (cstm_name === "") {
      setMessageName("Please enter the name.");
      setVerif_all_data(1);
    } else setMessageName("");

    if (cstm_username === "") {
      setMessageUsername("Please enter the username.");
      setVerif_all_data(1);
    } else setMessageUsername("");
    if (cstm_password === "") {
      setMessagePassword("Please enter the password.");
      setVerif_all_data(1);
    } else setMessagePassword("");

    //if (messagePhone != "" && messagename != "") {
    if (
      cstm_code !== "" &&
      cstm_name !== "" &&
      cstm_username !== "" &&
      cstm_password !== ""
    ) {
      if (idCustomer) {
        Axios.post(url + "?p=update_customer", {
          name: cstm_name.toUpperCase(),
          siteId: cstm_contact,
          code: cstm_code,
          password: cstm_password,
          username: cstm_username,
          custmerID: idCustomer,
        }).then((response) => {
          if (response.data.type_action) {
            notifySuccess("Customer informations updated");
            setgenerated_password("");
          } else {
            if (response.data.errorno === "123456")
              notifyError("Code or Username already exist");
            else notifyError("Somrthing wrong");
          }
        });
      } else {
        Axios.post(url + "?p=add_customer", {
          name: cstm_name.toUpperCase(),
          siteId: cstm_contact,
          code: cstm_code,
          password: cstm_password,
          username: cstm_username,
        }).then((response) => {
          if (response.data.type_action) {
            notifySuccess("New customer has been added");
            setCstm_name("");
            setCstm_code("");
            setCstm_contact("");
            setCstm_password("");
            setCstm_username("");
            setVerif_all_data(1);
            setgenerated_password("");
          } else {
            if (response.data.errorno === "123456")
              notifyError("Code or Username already exist");
            else notifyError("Somrthing wrong");
          }
        });
      }
    }
  };
  const generatePassword = (n) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";

    for (let i = 0; i < n; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
    }
    setCstm_password(password);
    setgenerated_password(password);
  };

  //Visual part
  return (
    <Main>
      <Header />
      <Header_mobile />

      <div className="mt-50 col-xl-12 center-block" align="center">
        <div className="col-xl-3 bg-white ">
          <div className="pt-50">
            <h3>{!idCustomer ? t("Add_customer.1") : t("Add_customer.10")}</h3>
          </div>
          <form>
            <div className="form-group col-xl-12 mt-50">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_customer.2")}
                  <span className="text-danger pl-3 ">*</span>
                </label>
              </div>
              <input
                id="cstm-code"
                onChange={(e) => setCstm_code(e.target.value)}
                name="code"
                value={cstm_code}
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {messageCode}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_customer.3")}
                  <span className="text-danger pl-3">*</span>
                </label>
              </div>
              <input
                id="cstm-name"
                onChange={(e) => setCstm_name(e.target.value)}
                name="name"
                value={cstm_name}
                className="form-control rounded-0"
              />
              <div id="cstm-name-error" className="text-danger pt-2">
                {messageName}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_customer.4")}
                  <span className="text-danger pl-3">*</span>
                </label>
              </div>
              <input
                id="cstm-username"
                onChange={(e) => setCstm_username(e.target.value)}
                name="username"
                value={cstm_username}
                className="form-control rounded-0"
              />
              <div id="cstm-username-error" className="text-danger pt-2">
                {messageUsername}
              </div>
            </div>

            <div className="form-group mb-4 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_customer.5")}
                  <span className="text-danger pl-3">*</span>
                </label>
                <IoMdRefreshCircle
                  size={20}
                  title="generate password"
                  className="ml-2"
                  onClick={() => generatePassword(6)}
                />
                <span className="text-danger ml-3"> {generated_password}</span>
              </div>
              <input
                type="password"
                id="cstm-password"
                onChange={(e) => {
                  setCstm_password(e.target.value);
                  setgenerated_password("");
                }}
                name="password"
                value={cstm_password}
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {messagePassword}
              </div>
            </div>

            {/* <div className="form-group mb-4 mt-3 col-xl-12">
              <div className="" align="left">
                <label className="font-weight-bold">
                  {t("Add_customer.6")}
                  <span className="text-danger pl-3"></span>
                </label>
              </div>
              <select
                id="cstm-contact"
                onChange={(e) => setCstm_contact(e.target.value)}
                value={cstm_contact}
                name="contact"
                className="form-control rounded-0"
              >
                <option key={0} value={0}>
                  {" "}
                </option>
                {all_site.length
                  ? all_site.map((d) => (
                      <option key={d.id} value={d.id}>
                        {" "}
                        {d.siteName}{" "}
                      </option>
                    ))
                  : null}
              </select>
              <div id="cstm-contact-error" className="text-danger pt-3">
                {messageContact}
              </div>
            </div> */}
          </form>
          <button
            onClick={add_customer}
            className="btn mb-4 bg-red col-xl-12 display-5"
          >
            {" "}
            {!idCustomer ? t("Add_customer.8") : t("Add_customer.11")}
          </button>
        </div>
      </div>

      <Footer />
    </Main>
  );
}

export default Add_customer;
